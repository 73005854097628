<!-- eslint-disable vue/no-mutating-props - Needed for Formulate integration -->

<template>
  <div
    :class="`formulate-input-element formulate-input-element--findandselect`"
    :data-type="context.type"
  >
    <VDropdown :shown="showDropdown" theme="findandselect">
      <div v-if="useTextarea">
        <textarea
          :data-cy="`find-and-select-input-${context.attributes.dataid}`"
          ref="inputfield"
          type="search"
          v-model="searchText"
          v-bind="context.attributes"
          @keydown.enter.prevent="set"
          @keydown.down.prevent="increment"
          @keydown.up.prevent="decrement"
          @input="updateSearch"
          @focus="onFocus"
          @blur="onBlur"
        />
        <div class="search"></div>
        <div class="search-clear" @mousedown="clear"></div>
      </div>
      <div v-else>
        <input
          :data-cy="`find-and-select-input-${context.attributes.dataid}`"
          ref="inputfield"
          type="search"
          v-model="searchText"
          v-bind="context.attributes"
          @keydown.enter.prevent="set"
          @keydown.down.prevent="increment"
          @keydown.up.prevent="decrement"
          @input="updateSearch"
          @focus="onFocus"
          @blur="onBlur"
        />
        <div class="search"></div>
      </div>

      <template #popper="{}">
        <LoadingDots v-if="isLoading" class="text-center buffer" />
        <div
          v-if="!isLoading"
          :data-cy="`find-and-select-dropdown-${context.attributes.dataid}`"
        >
          <ul ref="dropdown" class="formulate-input-dropdown buffer-none">
            <li
              v-for="(option, index) in filteredOptions"
              :data-value="option.item.value"
              :data-key="option.item.key"
              :key="option.item.key || option.item.value"
              :title="option.item.title"
              :data-is-selected="context.model === option.item.value"
              :data-is-highlighted="
                selection && selection.item.value === option.item.value
              "
              @mouseenter="selectedIndex = index"
              @mousedown="onMousedown"
              @click="set"
              :class="context.attributes.itemClass"
            >
              <component
                v-if="context.attributes.itemComponent"
                :is="context.attributes.itemComponent"
                :item="option.item"
              />
              <span v-else-if="!option.item.columns">{{
                option.item.label
              }}</span>
              <div
                v-for="(column, index) in option.item.columns"
                :key="index"
                :class="column.class"
              >
                {{ column.text }}
              </div>
            </li>
          </ul>
          <div v-if="showCount" class="text-subtle gutter buffer-sm">
            <small>{{
              $t('formulate:findAndSelect.resultCount', {
                count: filteredOptions.length,
              })
            }}</small>
          </div>
          <div
            v-if="!filteredOptions.length && !context.attributes.hideNoResult"
            class="flex-ver-center"
            :class="$style.noResultDropdown"
          >
            <strong>
              {{ $t('formulate:findAndSelect.noResults') }}
            </strong>
            <p v-if="context.attributes.noResultMessage">
              {{ context.attributes.noResultMessage }}
            </p>
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import Fuse from 'fuse.js';

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchText: '',
      selectedIndex: 0,
      performance: 0,
      hasFocus: false,
    };
  },
  created() {
    const val = this.context.model;
    const option = (this.context.options || []).find((o) => o.value === val);
    this.searchText = option?.label ?? '';
  },
  watch: {
    category(val, oldVal) {
      if (val !== oldVal) {
        this.context.model = undefined;
        this.searchText = '';
      }

      this.selectedIndex = 0;
      if (this.$refs['dropdown']) {
        this.$refs['dropdown'].scrollTop = 0;
      }
    },

    searchText(val, oldVal) {
      if (oldVal && !val) {
        this.$emit('clear');
      }
    },
  },
  computed: {
    showDropdown() {
      if (!this.hasFocus) return false;
      if (!this.searchText) return false;
      if (
        !this.showCount &&
        !this.isLoading &&
        !this.filteredOptions.length &&
        this.context.attributes.hideNoResult
      )
        return false;
      return true;
    },
    showCount() {
      return Boolean(this.context.attributes.showCount);
    },
    useTextarea() {
      return this.context.attributes.element === 'textarea';
    },
    category() {
      return this.context.attributes.category;
    },
    model() {
      return this.context.model;
    },
    selection() {
      if (this.filteredOptions[this.selectedIndex]) {
        return this.filteredOptions[this.selectedIndex];
      }
      return false;
    },
    filteredOptions() {
      if (this.context.attributes.filteredOptions)
        return Object.freeze(
          this.context.attributes.filteredOptions.map((item) => ({ item })) ||
            []
        );
      if (!Array.isArray(this.context.options)) return [];
      if (!this.searchText)
        return Object.freeze(this.context.options.map((item) => ({ item })));

      const fuse = new Fuse(this.context.options, {
        keys: this.searchKeys,
        useExtendedSearch: true,
        minMatchCharLength: 2,
      });

      // we want to filter strictly on thickness so terms like 18mm in the
      // search box are used to filter thickness whilte the remaining tokens
      // are used to search the other searchKeys
      const tokens = this.searchText.trim().split(' ');
      const thicknesses = tokens.filter((x) => x.match(/\d+mm/));
      const residue = tokens.filter((x) => !x.match(/\d+mm/));

      const filters = [];

      // add filter for thickness
      if (thicknesses.length > 0) {
        const f = thicknesses.map((x) => '=' + x).join('|');
        filters.push({ 'searchData.thickness': f });
      }

      // add filters for remaining search text that apply to all the search keys
      for (const r of residue) {
        const obj = (key, value) => Object.fromEntries([[key, value]]);
        filters.push({ $or: this.searchKeys.map((k) => obj(k, r)) });
      }

      const results = fuse.search(
        { $and: filters },
        { limit: this.context.limit }
      );
      return Object.freeze(results);
    },

    searchKeys() {
      return (this.context.attributes.searchKeys || []).map(
        (key) => `searchData.${key}`
      );
    },

    isLoading() {
      return this.context.attributes.loading || false;
    },
  },
  methods: {
    clear(e) {
      e.preventDefault();
      this.context.model = undefined;
      this.searchText = '';
      this.$emit('clear');
    },
    onMousedown(e) {
      e.preventDefault();
      this.context.model = this.selection.item.value;
    },
    onFocus() {
      this.hasFocus = true;
    },
    onBlur(e) {
      // this.hasFocus = false;
      this.$nextTick(() => {
        this.hasFocus = false;
      });
      if (this.context.attributes['select-on-blur']) {
        this.set();
      }
      this.context.blurHandler(e);
    },
    updateSearch() {
      this.context.model = undefined;
      this.selectedIndex = 0;

      if (this.$refs['dropdown']) {
        this.$refs['dropdown'].scrollTop = 0;
      }
      this.$emit('searchInput', this.searchText);
    },
    set() {
      this.context.model = this.selection.item.value;
      this.$emit('set', this.selection.item);
      this.searchText = this.selection.item.label;
      this.$refs['inputfield'].blur();
    },
    increment() {
      const length = this.filteredOptions.length;
      if (this.selectedIndex + 1 < length) {
        this.selectedIndex++;
      } else {
        this.selectedIndex = 0;
      }
    },
    decrement() {
      const length = this.filteredOptions.length;
      if (this.selectedIndex - 1 >= 0) {
        this.selectedIndex--;
      } else {
        this.selectedIndex = length - 1;
      }
    },
  },
};
</script>

<style module>
.noResultDropdown {
  padding: 2em 2em 1.5em;
}
</style>
