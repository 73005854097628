<template>
  <ModalContent
    v-bind="props"
    v-on="$listeners"
    v-if="$modal.isOpen(name)"
    #default="defaultSlotProps"
  >
    <h3 :class="$style.title" v-if="title">{{ title }}</h3>
    <slot v-bind="defaultSlotProps" />
  </ModalContent>
</template>

<script setup>
import ModalContent from './ModalContent.vue';
import { useModal } from '@/composables/plugins';
import { propsDefinition } from '@/helpers/modal-manager';
import { onMounted, watch, onUnmounted, ref } from 'vue';

const emit = defineEmits(['open', 'close']);
const props = defineProps(propsDefinition);

const modal = useModal();
const modalRef = ref(null);
const contentRef = ref(null);

onUnmounted(() => modal.deregister(props.name));
onMounted(() => {
  modal.register(props.name, { contentRef, modalRef, props });
  watch(
    () => modal.isOpen(props.name),
    (isOpen) => {
      if (!isOpen) return;
      emit('open');
    }
  );
});
</script>

<style module>
.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
</style>
