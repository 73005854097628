<template>
  <div :class="$style.wrapper">
    <button
      type="button"
      :title="$t(`formulate:button.increment`)"
      :disabled="context.attributes?.disabled"
      :class="$style.stepper"
      @click="value = value - 1"
    >
      -
    </button>
    <input
      :min="0"
      name="quantity"
      type="number"
      v-model="value"
      v-bind="context.attributes"
      :class="[context.classes.input, $style.input]"
      :disabled="context.attributes?.disabled"
      @blur="context.blurHandler"
    />
    <button
      type="button"
      :title="$t(`formulate:button.increment`)"
      :disabled="context.attributes?.disabled"
      :class="$style.stepper"
      @click="value = value + 1"
    >
      +
    </button>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { ToBeFixed } from '../../../../packages/constants/utility-types';

type Props = {
  context: ToBeFixed;
};

const props = defineProps<Props>();

const value = computed({
  get() {
    return props.context.model;
  },
  set(newValue) {
    // eslint-disable-next-line vue/no-mutating-props
    props.context.model = Math.max(0, parseInt(newValue || 0));
  },
});
</script>
<style module>
.wrapper {
  display: flex;
  gap: 0.5em;
}

.stepper {
  background-color: transparent;

  width: 2rem;
  height: 2rem;
  cursor: pointer;

  border: 1px solid var(--gray-5);
  border-radius: 5em;
}

.input {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;

  width: 4em;
  border: 1px solid var(--gray-5);
  border-radius: 1em;
  text-align: center;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
</style>
