<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <section class="miniStack">
    <p>Toggle UI element</p>

    <div :class="$style.wrapper">
      <ToggleBoolean v-model="isTrue" />
      <ToggleView v-model="isGrid" />
    </div>
  </section>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import ToggleBoolean from '../common/ToggleBoolean.vue';
import ToggleView from '@/elements/Order/ToggleView.vue';

const isTrue = ref<boolean | undefined>();
const isGrid = ref<'grid' | 'table'>('grid');
</script>
<style module>
.wrapper {
  width: 200px;
  height: 120px;
}
</style>
