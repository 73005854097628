<template>
  <input
    type="text"
    v-model="amount"
    @blur="$emit('blur', $event)"
    @input="setValue($event)"
  />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { toAmount, fromAmount } from '@/helpers/amount-input-helpers';

type Props = {
  value: number;
};

const props = defineProps<Props>();
const emit = defineEmits(['blur', 'input']);

const amount = ref<string>('');

watch(
  () => props.value,
  (newValue) => {
    amount.value = fromAmount(newValue);
  },
  { immediate: true }
);

function setValue(event: Event) {
  const target = event.target as HTMLInputElement;
  const newAmount = toAmount(target.value);

  // Format the input value to strip out any unwanted characters
  amount.value = fromAmount(newAmount);

  // If amount didn't change don't update
  if (newAmount === props.value) return;

  emit('input', newAmount);
}
</script>
