import { format } from '@/helpers/format-plugin';

export function toAmount(value: string | number | null): number | null {
  if (value === null || value === '') {
    return null;
  }
  const minusCount = value.toString().split('-').length - 1;
  const isNegative = minusCount % 2 === 1;
  const isPositive = value.toString().includes('+');
  let n: number;
  if (typeof value === 'string') {
    n = Number(value.replace(/\D/g, ''));
  } else {
    n = value;
  }
  return isNegative && !isPositive ? -n : n;
}

export function fromAmount(amount: number | null): string {
  if (amount === null) {
    return '';
  }
  return format.currency(amount, false);
}
