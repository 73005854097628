import axios, { AxiosError, AxiosInstance } from 'axios';
import i18next from 'i18next';
import baseURL from './base-url';

const api: AxiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  if (i18next.resolvedLanguage && config.headers) {
    config.headers['Accept-Language'] = i18next.resolvedLanguage;
  }
  return config;
});

export type DefaultBackendPayload = {
  message: string;
  type: string;
  errors: { message: string; source: string; path: string[] }[];
};

export function isAxiosError<ResponseType = DefaultBackendPayload>(
  error: unknown
): error is AxiosError<ResponseType> {
  return error instanceof Error && 'isAxiosError' in error;
}

export function toastMessageFromBackendError(
  error: AxiosError<DefaultBackendPayload>
) {
  const data = error.response?.data;
  let msg = data?.message || error.message;

  if (data?.errors) {
    data.errors.map((e) => {
      msg += `\n${e.message} (${e.source}.${e.path.join('.')}))`;
    });
  }

  return msg;
}

export function toastMessageFromAxiosError(error: unknown, fallback = '') {
  if (isAxiosError(error)) {
    return toastMessageFromBackendError(error);
  }
  if (error instanceof Error) {
    return error.message;
  }

  return fallback;
}

export default api;
