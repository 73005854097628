const API_BASE_URL_PRODUCTION = 'https://api.cutr.com';
const API_BASE_URL_AWS_STAGING = 'https://api.aws-staging.cutr.ai';
const API_BASE_URL_AWS_PRODUCTION = 'https://api.aws-production.cutr.ai';
const API_BASE_URL_GCP_STAGING = 'https://api.gcp-staging.cutr.com';
const API_BASE_URL_GCP_PRODUCTION = 'https://api.gcp-prod.cutr.com';

const hostToBaseURLMap = {
  'app.cutr.ai': API_BASE_URL_PRODUCTION,
  'app.cutr.com': API_BASE_URL_PRODUCTION,
  'app.aws-staging.cutr.ai': API_BASE_URL_AWS_STAGING,
  'app.aws-production.cutr.ai': API_BASE_URL_AWS_PRODUCTION,
  'app.gcp-staging.cutr.com': API_BASE_URL_GCP_STAGING,
  'app.gcp-prod.cutr.com': API_BASE_URL_GCP_PRODUCTION,
};
const baseURL =
  import.meta.env.VITE_API_BASE_URL || hostToBaseURLMap[location.host];

if (!baseURL) {
  throw new Error(`Unable to determine API baseURL for host ${location.host}`);
}

export default baseURL;
