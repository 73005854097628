import { getCurrentInstance } from 'vue';
import type {
  $track,
  $modal,
  $api,
  $toast,
  $format,
  $formulate,
  $feature,
  $helpLink,
  $route,
  $router,
  $t,
  $i18next,
  ToBeFixed,
} from '@/vite-env';

export {
  useRoute,
  useRouter,
  onBeforeRouteUpdate,
  onBeforeRouteLeave,
} from 'vue-router/composables';

export type Proxy = {
  $track: $track;
  $modal: $modal;
  $toast: $toast;
  $api: $api;
  $format: $format;
  $formulate: $formulate;
  $route: $route;
  $router: $router;
  $feature: $feature;
  $helpLink: $helpLink;
  $refs: ToBeFixed;
  $t: $t;
  $i18next: $i18next;
  $slots: ToBeFixed;
};

function getProxy() {
  return getCurrentInstance()?.proxy as unknown as Proxy;
}

export const useApi = () => getProxy().$api;
export const useTrack = () => getProxy().$track;
export const useModal = <T = unknown>() =>
  getProxy().$modal as Proxy['$modal'] & { context: T };
export const useToast = () => getProxy().$toast;
export const useFormat = () => getProxy().$format;
export const useFormulate = () => getProxy().$formulate;
export const useFeatureFlags = () => getProxy().$feature;
export const useHelpLinks = () => getProxy().$helpLink;
export const useRefs = () => getProxy().$refs;
export const useSlots = () => getProxy().$slots;
export const useTranslation = () => ({
  t: getProxy().$t,
  i18next: getProxy().$i18next,
});
