import { useFeatureFlags, useTranslation } from '@/composables/plugins';
import { DestroyUserSession } from '@/helpers/session-helpers';
import { useActiveUserStore } from '@/modules/store';
import { useRoute, useRouter } from 'vue-router/composables';
import { nav, pathIsAllowed } from '@/modules/routing';
import { computed } from 'vue';
import { DropdownMenuButton } from '@/components/common/types';

const IS_PRODUCTION = import.meta.env.VITE_CUTR_ENV === 'production';

export function useNavigation() {
  const userStore = useActiveUserStore();
  const router = useRouter();
  const route = useRoute();
  const { t } = useTranslation();
  const feature = useFeatureFlags();

  const goToRoute = function (name: string) {
    if (['settings', 'signin', 'signup'].includes(name)) {
      router.push({ name }).catch((err) => console.log(err.message));
      return;
    }

    if (name === 'signout') {
      // Destroy session then reroute to signin.
      DestroyUserSession(userStore, () => {
        router.push({ name: 'home' }).catch((err) => {
          console.log(err.message);
        });
      });
    }
  };

  const navLinks = computed(() => {
    const userType = userStore.activeSessionType || 'Public';

    return nav.filter(({ name, show }) => {
      if (show && show({ userStore, feature }) !== true) return false;

      return pathIsAllowed(userType, name);
    });
  });

  const accountMenuLinks = computed(() => {
    const signOutText = userStore.superUserId
      ? t('navigation:menu.backToAdmin')
      : t('navigation:menu.signOut');

    return (
      [
        userStore.canSwitch && {
          icon: 'sync',
          label: t('navigation:menu.switch-organisation'),
          to: {
            name: 'signin:selectOrganisation',
            query: { redirect: route.fullPath },
          },
          on: (hide) => ({ click: hide }),
        },
        {
          icon: 'person',
          label: t('navigation:menu.account'),
          to: {
            name: ['User', 'Admin'].includes(userStore.user.type)
              ? 'account'
              : 'settings',
          },
          on: (hide) => ({ click: hide }),
        },

        userStore.isAdmin && {
          icon: 'settings',
          label: 'Platform Config',
          to: { name: 'admin:config' },
          on: (hide) => ({ click: hide }),
        },
        userStore.isAdmin &&
          !IS_PRODUCTION && {
            label: 'Test Data',
            icon: 'auto_awesome',
            to: { name: 'testData' },
            on: (hide) => ({ click: hide }),
          },

        {
          icon: 'logout',
          class: 'alert',
          label: signOutText,
          on: (hide) => ({
            click() {
              hide();
              goToRoute('signout');
            },
          }),
        },
      ] as DropdownMenuButton[]
    ).filter(Boolean);
  });

  const isSignedIn = computed(
    () =>
      userStore.activeSessionType &&
      !['Public', 'Unauthenticated'].includes(userStore.activeSessionType)
  );

  return { goToRoute, navLinks, accountMenuLinks, isSignedIn };
}
