<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <section class="miniStack">
    <p>
      Tabbed navigation header.<br /><br />
      <code>&lt;TabNav :options="optionsArray" v-model=model /&gt;</code>
    </p>
    <div>
      <BaseCard>
        <TabNav :options="options" v-model="selected" />
      </BaseCard>
      <Callout
        :variant="selectedOption.value === 'o1' ? 'secondary' : undefined"
      >
        {{ selectedOption.label }}
      </Callout>
    </div>
  </section>
</template>
˝
<script setup>
import TabNav from '@/components/elements/TabNav.vue';
import { computed, ref } from 'vue';
const selected = ref('o1');
const options = [
  { label: 'Option 1', value: 'o1' },
  { label: 'Option 2', value: 'o2' },
];
const selectedOption = computed(() =>
  options.find((o) => o.value === selected.value)
);
</script>
<style scoped>
.boxes {
  align-items: flex-start;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.notificationBody {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr auto;

  & button {
    margin: 0 0 0.5rem;
  }
}
</style>
