import api from './api';
import { track } from './tracker';

// Helper to destroy a user session.
export function DestroyUserSession(store, cb = () => {}) {
  api
    .delete('/v2/sessions')
    .then(({ data }) => {
      track('signout', data.data);
      if (data.data?.sessionId) {
        const user = data.data;
        store.setActiveUser(user);
      } else {
        store.setActiveUserToPublic();
      }
      cb();
    })
    .catch((error) => {
      if (error.response) console.error(error.response.data);
    });
}
