<template>
  <a
    v-if="url"
    :href="url"
    @click="trackClick"
    v-tooltip="tooltipConfig"
    :target="target"
    class="help-link"
    >help_outlined</a
  >
</template>

<script>
import { toTooltipConfig } from '../../helpers/tooltip-helpers';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    tooltip: {
      type: [String, Object],
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  methods: {
    trackClick() {
      this.$track('click:help-link', {
        url: this.url,
        tooltip: this.tooltipConfig?.content,
      });
    },
    preventDefault(e) {
      e.preventDefault();
    },
  },
  computed: {
    tooltipConfig() {
      if (!this.tooltip) return;
      return {
        offset: [0, 10],
        ...toTooltipConfig(this.tooltip),
      };
    },
  },
};
</script>
