export default function validationParser(source, errors = []) {
  if (!errors.length) return {};

  return errors.reduce((acc, error) => {
    if (error.source === source) {
      const key = error.path.join('.0.');
      acc[key] = error.message;
    }
    return acc;
  }, {});
}
