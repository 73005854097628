import { ToBeFixed } from '@/vite-env';
import { onBeforeUnmount, onUnmounted, ref } from 'vue';

export function useEventListener<E extends keyof WindowEventMap>(
  type: E,
  listener: (this: Window, ev: WindowEventMap[E]) => ToBeFixed
) {
  window.addEventListener(type, listener);
  onBeforeUnmount(() => {
    window.removeEventListener(type, listener);
  });
}

export const useKeyDown = (
  key: KeyboardEvent['key'] | null,
  callback: (event: KeyboardEvent) => boolean
) => {
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) return;
    if (event.key !== key) return;

    if (callback(event)) {
      event.preventDefault();
    }
  };

  useEventListener('keydown', onKeyDown);
};

export function useMatchMedia(query: string) {
  const mediaQueryList = matchMedia(query);
  const matches = ref(mediaQueryList.matches);

  const process = (e: MediaQueryListEvent) => (matches.value = e.matches);
  const remove = () => mediaQueryList.removeEventListener('change', process);

  mediaQueryList.addEventListener('change', process);
  onUnmounted(remove);

  return {
    mediaQueryList,
    matches,
    remove,
  };
}
