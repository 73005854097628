import datePickerEn from '@sum.cumo/vue-datepicker/dist/locale/translations/en.esm';
import datePickerNl from '@sum.cumo/vue-datepicker/dist/locale/translations/nl.esm';
import {
  en as formulateEn,
  nl as formulateNl,
} from '@braid/vue-formulate-i18n';

export const FORMULATE_DATEPICKER_LOCALES = {
  nl: datePickerNl,
  en: datePickerEn,
};

export const FORMULATE_LOCALES = [formulateNl, formulateEn];
