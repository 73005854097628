import { CanceledError } from 'axios';
import { computed, onUnmounted, ref } from 'vue';
import { defineStore, acceptHMRUpdate } from 'pinia';
import api from '@/helpers/api';
import { liveUpdates } from '@/modules/live-updates';
import { Notification } from '@cutr/constants/notifications';

export const useNotifications = defineStore('notifications', () => {
  const notifications = ref([] as Notification[]);
  const isFetching = ref(false);

  function add(notification: Notification) {
    notifications.value.push(notification);
  }

  function remove(notification: Notification) {
    notifications.value = notifications.value.filter(
      (n) => n.id !== notification.id
    );
  }

  let controller: AbortController | null = null;
  async function fetch() {
    if (controller) {
      controller.abort();
    }
    isFetching.value = true;
    controller = new AbortController();

    await api
      .get('/v2/notifications', { signal: controller.signal })
      .then(({ data }) => {
        notifications.value = (data.data as Notification[]).filter(
          (n) => n.order
        );
      })
      .catch((e) => {
        if (e instanceof CanceledError) return;
        // nothing the user can do about this, just log it
        console.error(e);
      })
      .finally(() => {
        isFetching.value = false;
      });
  }

  function subscribe() {
    return liveUpdates.handleEvent('new_notification', () => fetch());
  }

  function clear() {
    notifications.value = [];
  }

  function edit(notification: Notification) {
    // edit the notification in place
    notifications.value = notifications.value.map((n) => {
      if (n.id === notification.id) {
        return notification;
      }
      return n;
    });
  }

  async function markRead(params: {
    id?: string[];
    orderId?: string[];
    resourceId?: string[];
    resourceType?: string[];
    type?: string[];
  }) {
    return api
      .put('/v2/notifications/mark-as-read', params)
      .then(({ data }) => {
        notifications.value = data.data as Notification[];
      })
      .catch((e) => {
        console.error(e);
        // revert the change
        return fetch();
      });
  }

  return {
    add,
    clear,
    edit,
    fetch,
    remove,
    subscribe,
    isFetching,
    markRead,
    markAllRead: () => markRead({}),
    notifications: computed(() => notifications.value),
    unreadCount: computed(
      () => notifications.value.filter((n) => !n.readAt).length
    ),
  };
});

export function useNotificationsSetup() {
  const notifications = useNotifications();

  notifications.fetch();
  const unsubscribe = notifications.subscribe();

  onUnmounted(() => {
    unsubscribe();
  });

  return notifications;
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotifications, import.meta.hot));
}
