<template>
  <div
    :class="{
      [$style.card]: true,
      [$style[variant || '']]: variant,
      [$style.border]: border,
    }"
    :role="role || (onClick ? 'button' : undefined)"
    @click="handleClick"
  >
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import { useTrack } from '@/composables/plugins.js';

type Props = {
  variant?: 'light' | 'dark' | 'darker' | 'transparent' | 'warning' | 'info';
  onClick?: (e: Event) => void;
  role?: string;
  tid?: string;
  border?: boolean;
};

const track = useTrack();
const props = defineProps<Props>();

const trackClick = () => {
  if (!props.tid) return;
  track('card-click', { tid: props.tid });
};

const handleClick = (event: Event) => {
  if (!props.onClick) return;
  trackClick();
  props.onClick(event);
};
</script>

<style module>
.card {
  padding: 1rem;
  border-radius: 0.75rem;
  background: var(--gray-2);
  border: 1px solid transparent;
}

.card[role='button'] {
  cursor: pointer;
}

.card[role='button']:hover {
  border-color: var(--color-signal);
}

.card .card {
  margin: 1rem 0 0;
}

.border {
  border: 1px solid var(--gray-5);
}

.light {
  background: var(--gray-1);
}

.dark {
  background: var(--gray-3);
}

.darker {
  background: var(--gray-4);
}

.warning {
  background: var(--gold-1);
  &.border {
    border-color: var(--gold-3);
  }
}

.info {
  background: var(--blue-1);
  &.border {
    border-color: var(--blue-3);
  }
}

.transparent {
  background: transparent;
}
</style>
