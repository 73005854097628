<template>
  <div :class="$style.mobileMenu">
    <article>
      <router-link
        v-for="(link, index) in navLinks"
        :key="index"
        @click="() => onClose()"
        :to="{ name: link.name }"
        class="menu-item"
      >
        {{ $t(`navigation:menu.${link.title}`) }}
      </router-link>

      <div v-if="isSignedIn">
        <h4 style="padding: 0 1rem">{{ store.activeUsername }}</h4>
        <ul :class="$style.unstyled">
          <li v-for="link in accountMenuLinks" :key="link.label">
            <component
              :is="'Button'"
              variant="menu"
              icon-before
              v-bind="omit(link, 'on')"
              v-on="link.on?.(onClose)"
            >
              {{ link.label }}
            </component>
          </li>
        </ul>
      </div>

      <NotificationMessages withCounter />
      <div class="flexAlign">
        <LangSelector
          class="flexAlign"
          style="margin-left: auto; font-size: 1rem"
        />
      </div>
    </article>
  </div>
</template>
<script setup lang="ts">
import omit from 'lodash/omit';
import { useActiveUserStore } from '@/modules/store';
import LangSelector from './LangSelector.vue';
import { useNavigation } from './composables';
import NotificationMessages from '../Notifications/NotificationMessages.vue';

defineProps<{ onClose(): void }>();

const store = useActiveUserStore();
const { navLinks, accountMenuLinks, isSignedIn } = useNavigation();
</script>
<style module>
.unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media (min-width: 580px) {
  .mobileMenu {
    display: none;
  }
}
.mobileMenu {
  position: fixed;
  inset: 0;
  background: var(--gray-1);
  z-index: 2;
  padding: calc(var(--navbarHeight) + 1rem) 1rem 1rem;
  overflow: auto;

  & a {
    text-decoration: none;
    color: var(--gray-8);
    user-select: none;
  }

  & a:hover {
    color: var(--gray-10);
  }

  & :global(.router-link-active) {
    color: var(--gray-9);
    font-weight: 600;
  }
}

.mobileMenu > article {
  display: grid;
  gap: 1rem;
  font-size: 20px;
  height: 100%;

  & > a {
    padding: 0 1rem;
  }
}
</style>
