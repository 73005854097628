<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <div v-if="env === 'development' && !userSignedIn" class="launchpad-grid">
    <div class="item">
      <h4>Sign in as (dev only)</h4>
      <div class="flexAlign flexWrap buffer-top">
        <Button
          v-for="login in logins"
          :key="login.value"
          size="sml"
          width="fluid-width"
          class="signal"
          :onClick="() => signinAs(login.value)"
          icon="arrow_forward"
        >
          {{ login.label }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useApi, useToast } from '@/composables/plugins';
import { useActiveUserStore } from '@/modules/store';
import { computed } from 'vue';

const api = useApi();
const toast = useToast();
const userStore = useActiveUserStore();

const logins = [
  { value: 'admin@cutr.ai', label: 'Admin' },
  { value: 'e2e-test+maker@cutr.ai', label: 'Maker' },
  { value: 'e2e-test+intmaker@cutr.ai', label: 'Maker-International' },
  { value: 'e2e-test+designer@cutr.ai', label: 'Designer' },
  { value: 'e2e-test+designer-coworker@cutr.ai', label: 'Designer Coworker' },
  { value: 'e2e-test+cutlist-agent-bnb@cutr.ai', label: 'Cutlist Agent' },
];

const env = import.meta.env.VITE_CUTR_ENV;
const userSignedIn = computed(
  () => !['Public', 'Unauthenticated'].includes(userStore.activeSessionType)
);

const signinAs = async (email: string) => {
  const credentials = { email, password: 'Trustno1' };
  return api
    .post('/v2/sessions', credentials)
    .then(() => location.reload())
    .catch((e) => toast.error(e.response.data.message));
};
</script>
