import IconEdgebanding from './IconEdgebanding.vue';
import LogoLiffin from './LogoLiffin.vue';
import LogoFinchBuildings from './LogoFinchBuildings.vue';
import LogoEventrent from './LogoEventrent.vue';
import LogoCampr from './LogoCampr.vue';
import IconCheckmark from './IconCheckmark.vue';
import { Component } from 'vue';

export const iconsMap: Record<string, Component> = {
  edgebanding: IconEdgebanding,
  checkmark: IconCheckmark,
  logoLiffin: LogoLiffin,
  logoFinchBuildings: LogoFinchBuildings,
  logoEventrent: LogoEventrent,
  logoCampr: LogoCampr,
};
