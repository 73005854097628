<template>
  <Modal
    name="confirm"
    @close="closeModal"
    :class="context.modalClass"
    @open="onOpen"
  >
    <h3
      :data-i="context.headerIcon"
      v-text="context.header || $t('common:modals.confirmHeader')"
      class="buffer-bottom-sm"
    ></h3>
    <div v-if="bodyParagraphs">
      <p v-for="paragraph in bodyParagraphs" :key="paragraph">
        {{ paragraph }}
      </p>
    </div>
    <div class="flexAlign">
      <Button
        ref="confirmRef"
        data-cy="btn-confirm"
        :icon="context.confirmIcon"
        iconBefore
        :class="context.confirmClass"
        :onClick="onConfirm"
        class="fluid-width"
      >
        {{ context.confirmText || 'Confirm' }}
      </Button>
      <Button
        v-if="context.onCancel"
        class="borderless fluid-width"
        :onClick="onCancel"
        :disabled="isConfirming"
      >
        {{ context.cancelText || 'Cancel' }}
      </Button>
    </div>
  </Modal>
</template>

<script>
export default {
  data() {
    return {
      isConfirming: false,
    };
  },
  computed: {
    context() {
      return this.$modal.context || {};
    },
    bodyParagraphs() {
      const { body } = this.context;
      if (!body) return;
      return Array.isArray(body) ? body : [body];
    },
  },

  methods: {
    async onConfirm() {
      this.isConfirming = true;
      return Promise.resolve(this.context.onConfirm()).finally(() => {
        this.isConfirming = false;
        this.$modal.close('confirm', { trigger: 'confirm' });
      });
    },

    async onCancel() {
      const cb = this.context.onCancel || (() => undefined);
      return Promise.resolve(cb()).finally(() => {
        this.$modal.close('confirm', { trigger: 'cancel' });
      });
    },

    closeModal() {
      if (this.context?.onClose) {
        return this.context.onClose();
      }
      if (this.context?.onCancel) {
        return this.context.onCancel();
      }
    },
    onOpen() {
      this.$refs.confirmRef?.$el?.focus();
    },
  },
};
</script>
