<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <section class="miniStack">
    <p>
      To call out attention to something.<br /><br />
      <code
        >&lt;Callout icon="warning"
        variant="warning"&gt;Warning!&lt;/Callout&gt;</code
      >
    </p>
    <div class="miniStack">
      <div v-for="variant in variants" :key="variant">
        <h3>{{ variant }}</h3>
        <BaseCard>
          <Callout :variant="variant" icon="volunteer_activism">
            Callout with variant =
            <strong> {{ variant }} </strong>.
          </Callout>
        </BaseCard>
        <div />
      </div>
    </div>

    <div>
      <h3>Examples</h3>
      <div class="boxes">
        <Callout icon="notifications">
          <div class="notificationBody">
            <div>
              <strong>Potential manufacturing issues found.</strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolor magna aliqua.
              </p>
            </div>
            <div>
              <Button color="signal" icon="visibility" icon-before>View</Button>
              <Button color="alert-outline">Delete</Button>
            </div>
          </div>
        </Callout>

        <Callout icon="info" variant="warning">
          <div>
            <strong>Potential manufacturing issues found.</strong>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolor magna aliqua.
            </p>
            <Button color="white" width="fluid-width">View </Button>
          </div>
        </Callout>
      </div>
      <div class="boxes">
        <Callout :noBorder="true">
          <div class="flex-hor-between">
            <h4 data-i="warning_amber">title</h4>
            <div class="text-subtle">text subtle</div>
          </div>
          <p>description</p>
          <p>
            <span class="text-subtle">Recommendation</span>
            <br />
            Lorem ipsum dolor sit amet.
          </p>
          <Callout variant="grey" state="focus"
            ><span class="$style.iconAdjacent"
              >Highlight all parts</span
            ></Callout
          >
          <Callout variant="grey" state="focus">
            <div class="flex-hor-between">
              <span class="$style.iconAdjacent">View individual parts</span>
            </div>
          </Callout>
        </Callout>
      </div>
    </div>
  </section>
</template>
<script>
import { variants } from '@/components/elements/CalloutProps';
export default {
  data() {
    return { variants };
  },
};
</script>
<style scoped>
.boxes {
  align-items: flex-start;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.notificationBody {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr auto;

  & button {
    margin: 0 0 0.5rem;
  }
}
</style>
