<template>
  <div>
    <i data-i="language" />
    <a
      role="button"
      :class="{ active: isActiveLng('en') }"
      @click="handleLangSwitch('en')"
      >{{ $t('navigation:menu.en') }}</a
    >
    <span>&nbsp;|&nbsp;</span>
    <a
      role="button"
      :class="{ active: isActiveLng('nl') }"
      @click="handleLangSwitch('nl')"
      >{{ $t('navigation:menu.nl') }}</a
    >
  </div>
</template>
<script setup lang="ts">
import { useTranslation } from '@/composables/plugins';
const { i18next } = useTranslation();
const isActiveLng = (lng: string) => i18next.language === lng;
const handleLangSwitch = i18next.changeLanguage;
</script>
