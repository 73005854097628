export function fileHasText(file, searchText) {
  const searchString = (file.name || '').toLowerCase();
  return normalizeSearchText(searchString).includes(
    normalizeSearchText(searchText)
  );
}

function normalizeSearchText(text) {
  return text.toLowerCase().replace(/[\W_]/g, '');
}

export const fileTypeData = {
  step: {
    label: 'Step',
    type: '3d',
    extensions: ['.stp', '.st', '.step'],
    regex: '.step|.stp|.st',
  },
  sketchup: {
    label: 'Sketchup',
    type: '3d',
    extensions: ['.skp'],
    regex: '.skp',
  },
  dxf: {
    label: 'DXF',
    type: '2d',
    extensions: ['.dxf'],
    regex: '.dxf',
  },
  dwg: {
    label: 'DWG',
    type: '2d',
    extensions: ['.dwg'],
    regex: '.dwg',
  },
  pdf: {
    label: 'PDF',
    type: 'pdf',
    extensions: ['.pdf'],
    regex: '.pdf',
  },
  png: {
    label: 'PNG',
    type: 'image',
    extensions: ['.png'],
    regex: '.png',
  },
  jpg: {
    label: 'JPG',
    type: 'image',
    extensions: ['.jpg', '.jpeg'],
    regex: '.jpg|.jpeg',
  },
  webp: {
    label: 'WEBP',
    type: 'image',
    extensions: ['.webp'],
    regex: '.webp',
  },
  heic: {
    label: 'HEIC',
    type: 'image',
    extensions: ['.heic'],
    regex: '.heic',
  },
};

export const FILE_TYPE_LABELS = Object.keys(fileTypeData).reduce(
  (labels, key) => {
    labels[key] = fileTypeData[key].label;
    return labels;
  },
  {}
);
export const FILE_TYPES = Object.values(FILE_TYPE_LABELS);
export const FILE_EXTENSIONS = SUPPORTED_FILE_EXTENSIONS();

export function fileHasType(file, type) {
  if (type === 'any') return true;
  if (!fileTypeData[type]) return false;
  return new RegExp(fileTypeData[type].regex, 'i').test(extname(file.name));
}

export function removeFileExtension(filename) {
  return filename.split('.')[0];
}

export function SUPPORTED_FILE_TYPES() {
  return Object.keys(fileTypeData);
}

export function SUPPORTED_FILE_EXTENSIONS() {
  return Object.keys(fileTypeData).flatMap(
    (fileType) => fileTypeData[fileType].extensions
  );
}

export function extname(filename) {
  if (!filename) return '';

  // fast and efficient, but a bit magic
  // see https://stackoverflow.com/a/12900504 for a great explanation
  const ext = filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);

  return ext ? `.${ext}` : '';
}
