<template>
  <svg
    class="progress-circle"
    viewBox="0 0 100 100"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="progress-circle-background"
      r="50"
      cx="50"
      cy="50"
      fill="white"
    />
    <circle
      class="progress-circle-progress"
      r="25"
      cx="50"
      cy="50"
      stroke-width="50"
      :stroke-dasharray="`${dashLength} 1000`"
      transform="rotate(-90) translate(-100)"
    />
  </svg>
</template>

<script>
const CIRCUMFERENCE = 2 * Math.PI * 25;
export default {
  props: {
    progress: {
      type: Number,
    },
  },
  computed: {
    dashLength() {
      return (CIRCUMFERENCE / 100) * this.progress;
    },
  },
};
</script>
