<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <section class="miniStack">
    <p>
      Inline element available everywhere in our app:
      <code>&lt;Badge label="msg" icon="message" /&gt;</code> (
      <Badge label="msg" icon="message" />)
    </p>
    <div>
      <h3>variants</h3>
      <p>
        props: <code>{{ variants.join(', ') }}</code>
      </p>
      <div class="flexAlign">
        <Badge
          v-for="variant in variants"
          :variant="variant"
          :label="variant"
          :key="variant"
        />
      </div>
    </div>
    <div />
    <div>
      <h3>with icon</h3>
      <p>
        <code>&lt;Badge label="msg" icon="volunteer_activism" /&gt;</code>
      </p>

      <div class="flexAlign">
        <Badge
          v-for="variant in variants"
          icon="volunteer_activism"
          :variant="variant"
          :label="variant"
          :key="variant"
        />
      </div>
    </div>
    <div />
    <div>
      <h3>icon only</h3>
      <p><code>&lt;Badge icon="volunteer_activism" /&gt;</code></p>
      <div class="flexAlign">
        <Badge
          v-for="variant in variants"
          icon="volunteer_activism"
          :variant="variant"
          :key="variant"
        />
      </div>
    </div>
    <p />
    <div>
      <h3>Default Slot</h3>
      <p>
        You can pass a children to Badge, it will be rendered as-is<br /><br />
        <code>&lt;Badge&gt;&lt;Badge label="inception"&gt;&lt;/Badge&gt;</code>
      </p>
      <div class="flexAlign">
        <Badge v-for="variant in variants" :variant="variant" :key="variant">
          <Badge label="inception!" />
        </Badge>
      </div>
      <p>
        <code
          >&lt;Badge icon="check"&gt;&lt;Badge
          icon="check"&gt;&lt;/Badge&gt;</code
        >
      </p>
      <div class="flexAlign">
        <Badge
          v-for="variant in variants"
          :variant="variant"
          :key="variant"
          icon="check"
        >
          <Badge icon="check" />
        </Badge>
      </div>
    </div>
    <p />
    <div>
      <h3>Examples</h3>
      <p>Badges used in the wild.</p>
      <BaseCard style="max-width: 500px">
        <div class="flexAlign opposites">
          <strong data-i="business">Business Name</strong>
          <Badge icon="check" variant="success">Approved</Badge>
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </BaseCard>
      <p />
      <div :class="$style.bar">
        <Button size="sml" class="lock" icon="lock" icon-before>lock</Button>
        <div class="flexAlign">
          <Badge variant="primary" label="Draft" />
          Order DXJXQ5
        </div>
        <Button size="sml" class="lock" icon="close">close</Button>
      </div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et
        <Badge icon="search">dolor</Badge> magna aliqua.
      </p>
    </div>

    <Button
      @click="
        () => {
          $toast.warning('This is a toast message');
          $toast.error('This is a toast message');
        }
      "
      >Error</Button
    >
    <Button
      @click="
        () => {
          $toast.success('This is a toast message');
        }
      "
      >Success</Button
    >
    <Button
      @click="
        () => {
          $toast('This is a toast message');
          $toast.info('This is a toast message');
        }
      "
      >Info</Button
    >
  </section>
</template>
<script>
import { variants } from '@/components/elements/Badge.vue';
export default {
  data() {
    return { variants };
  },
};
</script>
<style module>
.bar {
  padding: 0.5rem 1rem;
  background-color: var(--gray-10);
  color: var(--gray-3);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
