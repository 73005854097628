<template>
  <AuthPageLayout>
    <h2>
      <i18next :translation="$t('auth:title.signin')">
        <template #cutr>
          <span class="signal">Cutr</span>
        </template>
      </i18next>
    </h2>
    <Callout
      variant="warning"
      icon="info"
      v-if="$feature.isMaintenanceModeEnabled()"
    >
      <p>{{ $t('auth:callout.maintenance') }}</p>
    </Callout>
    <Callout icon="info" variant="callout" v-if="$route.query.redirect">
      {{ $t('auth:callout.selectOrganisation') }}
    </Callout>
    <h3>{{ $t('auth:actions.selectOrganisation') }}</h3>
    <SelectOrganisationBox
      :afterOrganisationSelected="afterOrganisationSelected"
    /><Button
      color="white"
      width="fluid-width"
      icon-before
      icon="west"
      @click="() => $router.back()"
      >{{ $t('common:actions.back') }}</Button
    >
  </AuthPageLayout>
</template>

<script setup lang="ts">
import AuthPageLayout from '@/components/common/AuthPageLayout.vue';
import { useRouter, useRoute } from 'vue-router/composables';
import SelectOrganisationBox from '@/elements/SelectOrganisationBox.vue';

const router = useRouter();
const route = useRoute();

const afterOrganisationSelected = async () => {
  await router.push((route.query?.redirect as string) || '/home');
};
</script>
