<template>
  <Toggle
    :firstOption="firstOption"
    :secondOption="secondOption"
    :disabled="disabled"
    :value="value"
    @input="$emit('input', $event)"
    class="text-small"
  ></Toggle>
</template>
<script setup lang="ts">
import { useTranslation } from '@/composables/plugins';

const { t } = useTranslation();

const firstOption = {
  label: t(`order:fileOptions.gridView`),
  value: 'grid',
  icon: 'grid_view',
};
const secondOption = {
  label: t(`order:fileOptions.tableView`),
  value: 'table',
  icon: 'table_rows',
};

type Props = {
  value: 'grid' | 'table';
  disabled?: boolean;
};

defineProps<Props>();
</script>
