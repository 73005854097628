import Button from './DocsButton.vue';
import Badge from './DocsBadge.vue';
import Callout from './DocsCallout.vue';
import TabNav from './DocsTabNav.vue';
import Formulate from './DocsFormulate.vue';
import Todos from './DocsTodos.vue';
import Toggle from './DocsToggle.vue';
import Launchpad from './DocsLaunchpad.vue';
import Table from './DocsTable.vue';

export const components = [
  { path: 'button', component: Button, label: 'Buttons' },
  { path: 'badge', component: Badge, label: 'Badge' },
  { path: 'callout', component: Callout, label: 'Callout' },
  { path: 'tabnav', component: TabNav, label: 'Tab Nav' },
  { path: 'formulate', component: Formulate, label: 'Formulate' },
  { path: 'todos', component: Todos, label: 'Todos' },
  { path: 'toggle', component: Toggle, label: 'Toggle' },
  { path: 'launchpad', component: Launchpad, label: 'Launchpad' },
  { path: 'table', component: Table, label: 'Table' },
  {
    path: 'editor',
    component: () => import('./DocsEditor.vue'),
    label: 'Editor',
  },
];
