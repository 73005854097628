<template>
  <footer :class="$style.footer">
    <p>
      {{ '&copy;' }}
      <a href="https://www.cutr.com" target="_blank">{{ 'Cutr.com' }}</a>
      <template v-if="$feature.isEnabled('ctr-7583-fsc-compliance')">
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <FSCLicense />
      </template>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <a
        :href="`http://help.cutr.ai/${$i18next.language?.slice(0, 2)}`"
        target="_blank"
        >{{ $t('common:footer.helpCenter') }}</a
      >
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <a href="#" @click="$track.openConsentManager">{{
        $t('common:footer.dataCollectionPreferences')
      }}</a>
    </p>
  </footer>
</template>

<script>
import FSCLicense from '@/components/elements/FSCLicense.vue';

export default {
  name: 'CutrFooter',
  components: {
    FSCLicense,
  },
};
</script>
<style module>
.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: var(--footerHeight);
  p {
    color: var(--gray-7);
    font-size: 0.6em;
  }
}
</style>
