<template>
  <div class="formulate-input-element formulate-input-element--inlineselect">
    <fieldset v-for="group in groups" :key="group.key">
      <legend v-if="group.label">{{ group.label }}</legend>
      <button
        type="button"
        v-for="option in group.options"
        :key="option.value"
        :class="{ selected: context.model === option.value }"
        @click="() => onClick(option.value)"
      >
        <template v-if="option.description">
          <div>
            <span>{{ option.label }}</span>
            <br />
            <span class="text-subtle">{{ option.description }}</span>
          </div>
          <span data-i="chevron_right" />
        </template>
        <template v-else>
          <span>{{ option.label }}</span>
          <span data-i="chevron_right" />
        </template>
      </button>
    </fieldset>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { EmitFn } from 'vue/types/v3-setup-context';

type PropsType = {
  context: {
    options: Option[];
    optionGroups: Record<string, Option[]>;
    model: string;
    rootEmit: EmitFn;
  };
};

type Option = { value: string; label: string; description?: string };
type OptionGroup = {
  label: string | null;
  key?: string | number | symbol;
  options: Option[];
};

const props = defineProps<PropsType>();

const groups = computed<OptionGroup[]>((): OptionGroup[] => {
  const { options, optionGroups } = props.context;
  if (options.length) return [{ label: null, options }];
  return Object.keys(optionGroups).map((label, index) => ({
    key: label || index,
    label,
    options: optionGroups[label],
  }));
});

const onClick = (value: string) => {
  // eslint-disable-next-line vue/no-mutating-props
  props.context.model = value;

  // Manually emit an input event with the second flag set to true to indicate the user clicked
  // Normally vue-formulate does not emit this when the value isn't changing (and vue-formulate emits it once when using v-model on a form).
  props.context.rootEmit('input', value, true);
};
</script>
