<template>
  <router-link
    v-if="tag === 'router-link'"
    :to="to"
    v-slot="{ href, navigate }"
    v-tooltip="tooltipConfig"
    :class="buttonClass"
    custom
  >
    <a :href="href" @click="(event) => handleClick(event, navigate)">
      <span class="icon material-icons-outlined" v-if="iconSecondary">
        {{ iconSecondary }}
      </span>

      <span v-if="hasContent" class="button-text"><slot></slot></span>

      <component
        class="icon"
        v-if="icon && IconComponent"
        :is="IconComponent"
        :progress="loadingProgress"
        :class="{ 'material-icons-outlined': IconComponent === 'span' }"
      >
        {{ icon }}
      </component>
    </a>
  </router-link>
  <component
    v-else
    :is="tag"
    :href="href"
    :target="target"
    :type="buttonType"
    :disabled="isDisabled"
    :class="buttonClass"
    @click="handleClick"
    v-tooltip="tooltipConfig"
  >
    <span class="icon material-icons-outlined" v-if="iconSecondary">
      {{ iconSecondary }}
    </span>

    <span v-if="hasContent" class="button-text"><slot></slot></span>

    <component
      v-if="IconComponent"
      class="icon"
      :is="IconComponent"
      :progress="loadingProgress"
      :class="{
        'material-icons-outlined':
          IconComponent === 'span' && !props.iconFilled,
        'material-icons': IconComponent === 'span' && props.iconFilled,
      }"
    >
      <slot name="icon">{{ icon }}</slot>
    </component>
  </component>
</template>

<script lang="ts">
export const colors = [
  'delete',
  'alert',
  'alert-outline',
  'signal',
  'green',
  'blue',
  'white',
  'unpill',
  'borderless',
  'active',
] as const;

export const sizes = [
  'fluid-width',
  'units-0',
  'units-1',
  'units-2',
  'units-3',
  'half',
  'full-width',
] as const;
</script>

<script setup lang="ts">
import { computed, ref, useSlots, watch } from 'vue';
import ProgressCircle from '../elements/ProgressCircle.vue';
import { ToBeFixed } from '@cutr/constants/utility-types';
import { Placement } from 'floating-vue';

export type Props = {
  href?: string;
  target?: string;
  disabled?: boolean;
  onClick?: (e: Event) => void;
  icon?: string;
  iconSecondary?: string;
  loading?: boolean;
  loadingProgress?: number;
  to?: Record<string, unknown> | string;
  tooltip?:
    | { content: string; delay?: ToBeFixed; placement?: Placement }
    | string;
  type?: 'button' | 'submit';
  iconBefore?: boolean;
  variant?: 'menu';
  color?: (typeof colors)[number];
  size?: 'sml';
  width?: (typeof sizes)[number];
  trackOverride?: () => void;
  iconFilled?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits(['click']);

const isSubmitting = ref(false);
const timer = ref(false);

watch(
  () => props.loading,
  (isLoading) => isLoading && setTimer()
);
watch(isSubmitting, (submitting) => submitting && setTimer());

const tooltipConfig = computed<{ content: string } | undefined>(() => {
  if (typeof props.tooltip === 'string') return { content: props.tooltip };
  return props.tooltip;
});
const buttonClass = computed(() => ({
  [variantClass.value]: true,
  'base-button': true,
  'icon-only': !hasContent.value,
  'icon-before': props.iconBefore,
  'icon-after': props.icon && !props.iconBefore,
  [props.color || '']: true,
  [props.size || '']: true,
  [props.width || '']: true,
  inactive: tag.value !== 'button' && isDisabled.value,
}));
const variantClass = computed(() => {
  if (props.variant === 'menu') return 'button-menu';
  return 'button-pill';
});
const buttonType = computed(() => {
  if (tag.value !== 'button') return;
  return props.type || 'button';
});
const tag = computed(() => {
  if (props.href) return 'a';
  if (props.to) return 'router-link';
  return 'button';
});
const hasContent = computed(() => Boolean(slotText.value));
const isDisabled = computed(() => props.disabled || isLoading.value);
const isLoading = computed(
  () => props.loading || isSubmitting.value || timer.value
);

const slots = useSlots();
const slotText = computed(() => {
  const defaultSlot = slots.default?.();
  if (!defaultSlot || !defaultSlot.length) return '';

  return (
    defaultSlot[0].text ||
    defaultSlot[0].children?.[0]?.text ||
    ''
  ).trim();
});

const IconComponent = computed(() => {
  if (props.loadingProgress) return ProgressCircle;
  if (isLoading.value) return 'LoadingDots';
  if (!props.icon) return null;
  return 'span';
});

function setTimer() {
  timer.value = true;
  setTimeout(() => {
    timer.value = false;
  }, 500);
}

function handleClick(event: Event, navigate?: (e: Event) => void) {
  if (props.disabled && event.preventDefault) {
    event.preventDefault();
  }

  trackClick();
  if (props.onClick) {
    isSubmitting.value = true;
    Promise.resolve(props.onClick(event)).finally(() => {
      isSubmitting.value = false;
    });
  } else {
    emit('click', event);
  }
  if (navigate && !props.disabled) navigate(event);
}

function trackClick() {
  if (props.trackOverride) {
    props.trackOverride();
    return;
  }
}
</script>
