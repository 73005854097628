<template>
  <!-- eslint-disable vue/no-bare-strings-in-template -->
  <ul :class="$style.pagination" aria-label="pagination">
    <li>
      <button
        type="button"
        @click="onClickPage(1)"
        :disabled="currentPage == 1"
        :class="$style.icon"
      >
        skip_previous
      </button>
    </li>

    <li>
      <button
        type="button"
        @click="onClickPage(currentPage - 1)"
        :disabled="currentPage === 1"
        :class="$style.icon"
      >
        navigate_before
      </button>
    </li>

    <li :class="{ [$style.hide]: startPage === 1 }">
      <span :class="$style.icon">more_horiz</span>
    </li>

    <li v-for="page in pages" :key="page.name">
      <button
        type="button"
        @click="onClickPage(page.name)"
        v-bind="getPageProps(page)"
      >
        {{ page.name }}
      </button>
    </li>

    <li :class="{ [$style.hide]: !hasPagesAfter }">
      <span :class="$style.icon">more_horiz</span>
    </li>

    <li>
      <button
        type="button"
        @click="onClickPage(currentPage + 1)"
        :disabled="currentPage === totalPages"
        :class="$style.icon"
      >
        navigate_next
      </button>
    </li>

    <li>
      <button
        type="button"
        @click="onClickPage(totalPages)"
        :disabled="currentPage === totalPages"
        :class="$style.icon"
      >
        skip_next
      </button>
    </li>

    <li :class="$style.last">
      <slot name="pageInfo">{{
        $t('common:pagination.pageInfo', { currentPage, totalPages })
      }}</slot>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed, useCssModule } from 'vue';
import { ButtonHTMLAttributes } from 'vue/types/jsx';

type Props = {
  perPage: number;
  totalPages: number;
  currentPage: number;
  maxVisibleButtons?: number;
};
const emit = defineEmits(['pagechanged']);
const props = withDefaults(defineProps<Props>(), { maxVisibleButtons: 3 });

const startPage = computed(() => {
  // When on the first page
  if (props.currentPage === 1) {
    return 1;
  }

  // When on the last page
  if (props.currentPage === props.totalPages) {
    return Math.max(props.totalPages - props.maxVisibleButtons + 1, 1);
  }

  // When inbetween
  return props.currentPage - 1;
});

const pages = computed(() => {
  const range = [];

  for (
    let i = startPage.value;
    i <=
    Math.min(startPage.value + props.maxVisibleButtons - 1, props.totalPages);
    i++
  ) {
    range.push({
      name: i,
      isDisabled: i === props.currentPage,
    });
  }

  return range;
});

const hasPagesAfter = computed(
  () => startPage.value + props.maxVisibleButtons <= props.totalPages
);

const $style = useCssModule();
const getPageProps = (page: { name: number; isDisabled: boolean }) => {
  const isCurrent = props.currentPage === page.name;
  return {
    disabled: page.isDisabled,
    'aria-label': isCurrent ? `page ${page.name}` : undefined,
    'aria-current': isCurrent ? 'page' : undefined,
    class: { [$style.active]: isCurrent },
  } as ButtonHTMLAttributes;
};

function onClickPage(page: number) {
  emit('pagechanged', page);
}
</script>

<style module>
.pagination {
  color: var(--gray-9);
  list-style-type: none;
  padding: 1rem 0;
  margin: 0;
  display: flex;
  gap: 0.25rem;
}

.pagination li {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  user-select: none;

  & .icon {
    font-family: 'Material Icons Outlined';
  }

  &.last {
    margin-left: auto;
  }

  &.hide {
    visibility: hidden;
  }
}

.pagination button {
  --size: 2rem;
  display: block;
  min-width: var(--size);
  min-height: var(--size);
  border-radius: var(--size);
  border: 1px solid var(--gray-5);
  background: var(--color-white);
  color: var(--gray-9);

  &:disabled {
    color: var(--color-lightGrey);
    background-color: var(--gray-3);
  }
  &.active {
    color: var(--color-signal);
    border-color: var(--color-signal);
    background: var(--color-white);
    font-weight: 500;
  }
}
</style>
