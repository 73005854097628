<template>
  <v-select
    :components="{ OpenIndicator, Deselect }"
    class="formulate-input-element formulate-input-element--vue-select"
    :data-type="context.type"
    v-model="value"
    :options="context.options"
    v-bind="context.attributes"
    :reduce="(x) => x.value"
    @close="onClose"
    @option:deselected="(evt) => $emit('optionDeselected', evt)"
    @option:selected="(evt) => $emit('optionSelected', evt)"
  />
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import vSelect from 'vue-select';
export default {
  components: {
    'v-select': vSelect,
  },
  emits: ['optionSelected', 'optionDeselected'],
  data: function () {
    return {
      Deselect: {
        render: (createElement) =>
          createElement('span', {
            class: {
              DeselectIndicator: true,
            },
            domProps: {
              innerHTML: '&times;',
            },
          }),
      },
      OpenIndicator: {
        render: (createElement) =>
          createElement('span', { class: { OpenIndicator: true } }),
      },
    };
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  computed: {
    value: {
      get() {
        return this.context.model;
      },
      set(val) {
        this.context.model = val;
      },
    },
  },
  methods: {
    onClose() {
      this.context.blurHandler();
    },
  },
};
</script>
<style>
.OpenIndicator {
  width: 0.8em;
  height: 0.5em;
  background-color: #ddd;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}
.DeselectIndicator {
  font-size: 1.3rem;
  line-height: 1;
  color: #aaa;
}
.formulate-input-element--vue-select[compact] {
  margin: 0;

  & .vs__selected {
    padding: 0.3em 0.6em;
  }
}
</style>
