import dayjs from './dayjs';
export const INVOICE_STATUS_OPTIONS = {
  draft: 'Draft',
  open: 'Open',
  paid: 'Paid',
  void: 'Void',
};

export const CATEGORY_OPTIONS = {
  maker: 'Maker',
  logistics: 'Logistics',
  design: 'Design',
  materials: 'Materials',
};

export const PAYMENT_METHOD_OPTIONS = {
  '': '',
  stripe: 'Stripe',
  bunq: 'Bunq',
};

const serialiseFileData = ({ data, field }) => {
  const fileData =
    data[field] && data[field].length === 1
      ? data[field][0]
      : { url: null, name: null };

  const serialised = {};
  serialised[`${field}URL`] = fileData.url;
  serialised[`${field}Name`] = fileData.name;
  return serialised;
};

const deSerialiseFileData = ({ data, field }) => {
  const deserialised = {};
  deserialised[field] = [
    { url: data[`${field}URL`], name: data[`${field}Name`] },
  ];
  return deserialised;
};

export const serialiseInvoiceData = (data) => {
  data = {
    ...data,
    totalAmount: Math.round(data.totalAmount * 100),
    totalInAmount: Math.round(data.totalInAmount * 100),
    vatAmount: Math.round(data.vatAmount * 100),
    paidAt: timeStampFromDate(data.paidAt),
    ...serialiseFileData({ data, field: 'invoicePDF' }),
    ...serialiseFileData({ data, field: 'proformaPDF' }),
  };

  // formulate adds 1 empty item to group by default -> filter empty items
  data.orders = (data.orders || [])
    .filter((o) => o.id && o.amount)
    .map((o) => ({
      id: o.id,
      orderSupplierInvoice: { amount: Math.round(o.amount * 100) },
    }));

  data.items = (data.items || []).map((i) => ({
    ...i,
    amount: Math.round(i.amount * 100),
  }));

  return data;
};

export const deSerialiseInvoiceData = (data) => {
  data = {
    ...data,
    totalAmount: data.totalAmount / 100,
    totalInAmount: data.totalInAmount / 100,
    vatAmount: data.vatAmount / 100,
    paidAt: dateFromTimestamp(data.paidAt),
    ...deSerialiseFileData({ data, field: 'invoicePDF' }),
    ...deSerialiseFileData({ data, field: 'proformaPDF' }),
  };

  data.orders = (data.orders || []).map((o) => ({
    id: o.id,
    amount: o.orderSupplierInvoice.amount / 100,
  }));

  data.items = (data.items || []).map((i) => ({
    ...i,
    amount: i.amount / 100,
  }));

  return data;
};

export const dateFromTimestamp = (stamp) =>
  stamp && dayjs(stamp).format('YYYY-MM-DD');

export const timeStampFromDate = (date) => date && `${date}T00:00:00.000Z`; // hardcoding to be at midnight UTC

export const diffToDate = (now, dueDate) => {
  if (!dueDate) return null;

  const invoiceDate = dayjs(dueDate);
  const diff = now.diff(invoiceDate, 'day');

  if (diff === 0 && now.diff(invoiceDate, 'second') < 0) {
    return -1;
  }
  if (diff === 0 && now.diff(invoiceDate, 'second') > 0) {
    return 1;
  }

  return diff === 0 ? diff + 1 : diff;
};

export const isOverdue = (invoice) => diffToDate(dayjs(), invoice.dueDate) > 0;
