<template>
  <Teleport to="#portal-target" :disabled="inline">
    <transition appear name="fade-fast">
      <div
        :ref="modal.current.modalRef"
        role="dialog"
        data-cy="modal"
        :data-name="name"
        @keydown.esc="closeModal"
        class="modal-view"
        :class="{
          'modal-wide': wide,
          'modal-mediumWide': mediumWide,
          'modal-inline': inline,
        }"
      >
        <div tabindex="0" />
        <Button
          v-if="!disableClose"
          :aria-label="$t('common:modals.closeModal')"
          data-cy="modal-close"
          @click="closeModal"
          :tooltip="{
            content: $t('common:actions.close'),
            delay: { show: 1000 },
          }"
          class="sml modal-close-btn white"
          icon="close"
          tabindex="-1"
        />

        <div class="content" ref="contentRef" :class="props.contentClass">
          <slot :closeModal="closeModal" :context="context"></slot>
        </div>
        <div tabindex="0" />
      </div>
    </transition>
    <transition appear name="fade-fast">
      <div class="modal-overlay" :class="{ inline }" @click="closeModal"></div>
    </transition>
  </Teleport>
</template>

<script setup>
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- actually used, but vue2-teleport does not ship types, linter freaks out
import Teleport from 'vue2-teleport';
import { useModal } from '@/composables/plugins';
import { computed, watchEffect, ref } from 'vue';

import { propsDefinition } from '@/helpers/modal-manager';

const focusableElements =
  'input, select, textarea, button:not([tabindex="-1"]), [href], [tabindex]:not([tabindex="-1"])';

const emit = defineEmits(['close']);
const props = defineProps(propsDefinition);

const modal = useModal();
const contentRef = modal.current.contentRef;
const modalRef = modal.current.modalRef;
const lastFocus = ref(null);

const isOpen = computed(() => modal.isOpen(props.name));
const context = computed(
  () => (modal.isOpen(props.name) && modal.context) || {}
);

watchEffect(() => {
  if (!modalRef.value) return;
  if (!isOpen.value) return;

  modal.current.closeModal = closeModal;

  // make sure listener has been cleaned up
  modalRef.value.removeEventListener('keyup', onKey);
  modalRef.value.addEventListener('keyup', onKey);
  if (!lastFocus.value) {
    // keep track of last focused element
    // to restore focus when modal is closed
    lastFocus.value = document.activeElement;
  }

  const firstFocusableEl = modalRef.value.querySelector(focusableElements);
  firstFocusableEl?.focus();
});

function closeModal(event) {
  if (props.disableClose) return;

  // 'close' event must be emitted before calling $modal.close().
  // Listeners may rely on $modal.context which is cleared when
  // calling $modal.close().
  emit('close', event);
  modalRef.value.removeEventListener('keyup', onKey);

  modal.close(props.name);
  event?.preventDefault();
  lastFocus.value?.focus?.();
  lastFocus.value = null;
}

function onKey(event) {
  if (event.key === 'Escape') {
    event.preventDefault();
    closeModal(event);
  }

  if (event.key === 'Tab') {
    const focusableEls = modalRef.value.querySelectorAll(focusableElements);
    const firstFocusableElement = focusableEls[0];
    const lastFocusableElement = focusableEls[focusableEls.length - 1];

    // trap that focus!
    if (!event.shiftKey && document.activeElement === lastFocusableElement) {
      event.preventDefault();
      firstFocusableElement.focus();
    }

    if (event.shiftKey && document.activeElement === firstFocusableElement) {
      event.preventDefault();
      lastFocusableElement.focus();
    }
  }
}
</script>

<style>
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.15s ease;
}
</style>
