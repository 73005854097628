<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <section class="miniStack">
    <div>
      <BaseCard variant="light" class="buffer-top">
        <h3>A generic table with selection</h3>
        <GenericTable
          class="buffer-top"
          :class="$style.table"
          withSelection
          @onSelected="handleSelected"
          :rows="
            files.map((f) => {
              return {
                ...f,
                ...f.options,
                isSelected: selectedRows.includes(f.id),
              };
            })
          "
          :headers="[
            {
              text: 'Name',
              value: 'name',
              style: 'text-align: left; --justifyContent: normal;',
            },
            {
              text: 'Revision',
              value: 'revisionNumber',
            },
            {
              text: 'Parts in file',
              value: 'totalParts',
              style: 'width: 15ch',
            },
            {
              text: 'Quantity',
              value: 'quantity',
              style: 'width: 0',
            },
            {
              text: 'Assembly',
              value: 'assemblyRequested',
            },
            {
              text: 'Reference',
              value: 'reference',
            },
          ]"
        />
      </BaseCard>
      <BaseCard variant="light" class="buffer-top">
        <h3>A generic table with sorting</h3>
        <GenericTable
          class="buffer-top"
          :class="$style.table"
          withSorting
          :rows="
            files.map((f) => {
              return {
                ...f,
                ...f.options,
              };
            })
          "
          :headers="[
            {
              text: 'Name',
              value: 'name',
              style: 'text-align: left; --justifyContent: normal;',
            },
            {
              text: 'Revision',
              value: 'revisionNumber',
            },
            {
              text: 'Parts in file',
              value: 'totalParts',
              style: 'width: 15ch',
            },
            {
              text: 'Quantity',
              value: 'quantity',
              style: 'width: 0',
            },
            {
              text: 'Assembly',
              value: 'assemblyRequested',
            },
            {
              text: 'Reference',
              value: 'reference',
            },
          ]"
        />
      </BaseCard>
    </div>
    <div>&nbsp;</div>
    <h3>A generic table with custom renders</h3>
    <GenericTable
      :class="$style.table"
      withSelection
      withSorting
      @onSelected="handleSelected"
      :rows="
        files.map((f) => {
          return {
            ...f,
            ...f.options,
            isSelected: selectedRows.includes(f.id),
          };
        })
      "
      :headers="[
        {
          text: 'Name',
          value: 'name',
          style: 'text-align: left; --justifyContent: normal;',
        },
        {
          text: 'Revision',
          value: 'revisionNumber',
          style: 'width: 0',
        },
        {
          text: 'Parts in file',
          value: 'totalParts',
          style: 'width: 15ch',
        },
        {
          text: 'Quantity',
          value: 'quantity',
          style: 'width: 0',
        },
        {
          text: 'Select',
          value: 'id',
          style: 'width: 0',
          disableSort: true,
        },
        {
          text: 'Assembly',
          value: 'assemblyRequested',
          style: 'width: 0',
        },
        {
          text: 'Reference',
          value: 'reference',
          style: 'width: 18ch',
        },
      ]"
      class="buffer-top"
      style="
        --theadBackground: var(--gray-4);
        --tbodyBackground: var(--gray-1);
        --thPadding: 1rem 0.5rem;
      "
    >
      <template #id>
        <Button
          icon="layers"
          @click.stop
          class="sml white"
          :tooltip="$t('order:fileOptions:selectAndGotoMaterialTabTooltip')"
        />
      </template>
      <template #quantity="{ row }">
        <input
          type="number"
          v-model="getFile(row.id).options.quantity"
          @click.stop
        />
      </template>
      <template #assemblyRequested="{ row }">
        <div class="cellWrapper pad-xs">
          <ToggleBoolean v-model="getFile(row.id).options.assemblyRequested" />
        </div>
      </template>
      <template #reference="{ row }">
        <input type="text" v-model="getFile(row.id).reference" @click.stop />
      </template>
    </GenericTable>
  </section>
</template>
<script setup lang="ts">
import { ref } from 'vue';
const files = ref([
  {
    id: 'bla',
    name: 'Very long name for this file without any extension',
    reference: 'ref1',
    revisionNumber: 1,
    totalParts: 10,
    options: {
      quantity: 1,
      assemblyRequested: false,
    },
  },
  {
    id: 'blabla',
    name: 'file_corpus_dot_meta.splt',
    reference: 'ref2',
    revisionNumber: 2,
    totalParts: 20,
    options: {
      quantity: 2,
      assemblyRequested: true,
    },
  },
  {
    id: 'blablabla',
    name: 'file_with_revision_after_approval_from_customer.splt',
    reference: 'ref3',
    revisionNumber: 3,
    totalParts: 30,
    options: {
      quantity: 3,
      assemblyRequested: false,
    },
  },
]);

const selectedRows = ref<string[]>([]);
function handleSelected(checked: boolean, file: { id: string }) {
  const selected = [...selectedRows.value];

  if (checked) {
    selected.push(file.id);
  } else {
    selected.splice(
      selected.findIndex((id) => id === file.id),
      1
    );
  }

  selectedRows.value = selected;
}

function getFile(id: string) {
  return files.value.find((f) => f.id === id)!;
}
</script>
<style module>
.table input {
  border: 1px solid var(--gray-5);
  background-color: var(--color-white);
  max-width: 12ch;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;

  &[type='number'] {
    max-width: 8ch;
  }
}
</style>
