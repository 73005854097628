<template>
  <Modal name="global" :disableClose="disableClose" @close="closeModal">
    <div v-if="header" class="header dotted">
      <h2 v-html="header"></h2>
    </div>
    <div v-if="body" class="body dotted" v-html="body"></div>

    <div v-if="buttons" class="flexAlign">
      <Button v-for="button in buttons" v-bind="button" :key="button.key">{{
        button.text
      }}</Button>
    </div>

    <div v-if="!buttons" class="modal-footer dotted">
      <Button
        data-cy="modal-button-1"
        :onClick="OnButton1"
        :class="{
          alert: button1alert,
          signal: button1signal,
          green: button1green,
          hide: !button1,
        }"
        class="spacing-5"
        :icon="button1icon"
      >
        {{ button1 }}
      </Button>
      <Button
        data-cy="modal-button-2"
        :onClick="OnButton2"
        :class="{
          alert: button2alert,
          signal: button2signal,
          green: button2green,
          hide: !button2,
        }"
        class="spacing-5"
        :icon="button2icon"
      >
        {{ button2 }}
      </Button>
    </div>
  </Modal>
</template>

<script>
export default {
  computed: {
    disableClose() {
      return Boolean(this.Content.disableClose);
    },
    buttons() {
      return this.$modal.context?.buttons;
    },
    Content() {
      return this.$modal.context || {};
    },
    header() {
      return this.Content.header;
    },
    body() {
      return this.Content.body;
    },
    button1() {
      return this.Content.button1label;
    },
    button1icon() {
      return this.Content.button1icon;
    },
    button1signal() {
      return this.Content.button1signal;
    },
    button1alert() {
      return this.Content.button1alert;
    },
    button1green() {
      return this.Content.button1green;
    },
    button2() {
      return this.Content.button2label;
    },
    button2icon() {
      return this.Content.button2icon;
    },
    button2signal() {
      return this.Content.button2signal;
    },
    button2alert() {
      return this.Content.button2alert;
    },
    button2green() {
      return this.Content.button2green;
    },
  },

  methods: {
    OnButton1: function () {
      const cb = this.Content.button1callback;
      if (cb) cb();
      this.$modal.close('global', { trigger: this.button1 });
    },
    OnButton2: function () {
      const cb = this.Content.button2callback;
      if (cb) cb();
      this.$modal.close('global', { trigger: this.button2 });
    },
    closeModal() {
      const cb = this.Content.closeCallback;
      if (cb) cb();
    },
  },
};
</script>
