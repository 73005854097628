<template>
  <div class="formulate-input-element--daysofweekpicker">
    <div
      v-for="checkbox in checkboxes"
      :key="checkbox.id"
      class="option--daysofweekpicker"
      :class="{ checked: checkbox.checked }"
      @click="() => onClick(checkbox)"
    >
      <input type="checkbox" :id="checkbox.id" />
      <label :for="checkbox.id">{{ checkbox.name }}</label>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import { daysOfWeek } from '../../helpers/date-helpers';

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  computed: {
    checkboxes() {
      return daysOfWeek('short').map((it, i) => ({
        id: i,
        name: it.toUpperCase(),
        checked: this.context.model.includes(i) ? true : false,
      }));
    },
  },

  methods: {
    onClick(checkbox) {
      checkbox.checked = !checkbox.checked;
      this.context.model = this.checkboxes
        .map((it) => (it.checked === true ? it.id : -1))
        .filter((it) => it != -1);
    },
  },
};
</script>
