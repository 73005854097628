import api from '../helpers/api';
import { sleep } from '../helpers/helpers';

const FeaturesPlugin = {
  install(Vue) {
    let isReady = false;
    const onReadySubscribers = [];

    const cachedFeatures = JSON.parse(localStorage.getItem('features') || '{}');
    const state = Vue.observable({ features: cachedFeatures });

    const refreshFeatureFlags = () =>
      api.get('/features').then(({ data }) => {
        Object.keys(data.features).forEach((featureName) => {
          Vue.set(state.features, featureName, data.features[featureName]);
        });
        localStorage.setItem('features', JSON.stringify(state.features));
        if (!isReady) {
          isReady = true;
          onReadySubscribers.forEach((fn) => fn());
        }
      });

    // fetch feature flags every 30s in the background
    async function fetchFeaturesLoop() {
      // eslint-disable-next-line
      while (true) {
        try {
          await refreshFeatureFlags();
        } catch (e) {
          console.error('Failed to fetch feature flags', e);
        }
        await sleep(30_000);
      }
    }
    fetchFeaturesLoop();

    const $feature = {
      onReady(fn) {
        if (typeof fn !== 'function') {
          throw new Error('onReady callback should be a function');
        }
        if (isReady) return fn();
        onReadySubscribers.push(fn);
      },
      isEnabled(featureName) {
        return state.features[featureName] || false;
      },
      getAbTestFlags() {
        return Object.fromEntries(
          Object.entries(state.features).filter(([k, v]) =>
            k.startsWith('ab-test')
          )
        );
      },
      isMaintenanceModeEnabled() {
        if (this.isEnabled('maintenance-mode-bypass')) return false;
        return (
          Boolean(import.meta.env.VITE_MAINTENANCE_MODE) ||
          this.isEnabled('maintenance-mode')
        );
      },
      refreshFeatureFlags,
    };

    Vue.$feature = $feature;
    Vue.prototype.$feature = $feature;
  },
};

export default FeaturesPlugin;
