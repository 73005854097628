<template>
  <svg
    v-if="customIcon"
    :class="$style.icon"
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    role="presentation"
    :style="`--icon-color: ${iconColor}`"
  >
    <component :is="customIcon" />
  </svg>
  <span
    v-else
    :data-i="icon"
    :class="{ iconFilled: fill }"
    :style="`color: ${iconColor}`"
  ></span>
</template>
<script lang="ts" setup>
import { computed, Component } from 'vue';
import { iconsMap } from './custom-icons';

type Props = {
  icon: string;
  width?: string | number;
  height?: string | number;
  iconColor?: string;
  fill?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  width: 24,
  height: 24,
  iconColor: 'currentColor',
});

const customIcon = computed<null | Component>(() => iconsMap[props.icon]);
</script>

<style module>
.icon {
  display: inline-block;
  margin-right: 0.3em;
  color: var(--icon-color, currentColor);
}
</style>
