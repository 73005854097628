<template>
  <div>
    <div class="file-uploader">
      <input
        :id="id"
        type="file"
        :name="name"
        :multiple="multiple === false ? false : true"
        @change="handleUpload"
        @click="() => tid && $track(tid, {})"
        :disabled="isLoading"
        @dragover.prevent="isOver = true"
        @dragleave.prevent="isOver = false"
        :data-is-drag-hover="isOver"
        :data-is-loading="isLoading"
        :data-ready="!isLoading"
        :accept="accept"
      />
      <label :for="id" class="file-uploader--label" style="padding: 2rem">
        <div class="flex-ver-center pad">
          <p>
            <strong>{{ label }}</strong>
          </p>

          <div data-i="upload_file" class="file-uploader--icon" />
          <p class="text-subtle">{{ fileTypeExtensions }}</p>
          <Button color="signal" style="max-width: 15rem">
            {{ $t('landing-page:fileInputButton') }}
          </Button>
        </div>
        <div
          class="file-uploader--progressbar"
          :class="{ invisible: !progresses.length }"
        >
          <div class="fill" ref="fillEl" />
        </div>
      </label>
    </div>
    <ul v-if="errors.length" class="text-error unstyled">
      <li v-for="message in errors" :key="message">{{ message }}</li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useTrack } from '@/composables/plugins';
import { TRACKING_HANDLERS } from '@/helpers/tracker/event-handlers';
import { CadFile } from '@cutr/constants/order';
import { computed, onUnmounted } from 'vue';
import { onMounted } from 'vue';
import { ref } from 'vue';

type Props = {
  label: string;
  name: string;
  handleSingleFile: (
    newFile: string | Blob,
    progress: (progress: number) => void,
    handleError: (error: string) => void
  ) => void;
  accept: string;
  fileTypeLabels: string[];
  beforeFileUpload: () => Promise<void>;
  afterFileUpload: (filetype: string, source: string) => Promise<void>;
  multiple?: boolean;
  tid?: keyof typeof TRACKING_HANDLERS;
};

const id = 'launchpadUploader';
const props = defineProps<Props>();

const progresses = ref<number[]>([]);
const errors = ref<string[]>([]);
const isLoading = ref(false);
const isOver = ref(false);
const fillEl = ref<HTMLDivElement | null>(null);
const track = useTrack();

onMounted(() => {
  // Add a listener to the window to prevent drag/drops that miss the dropzone
  // from opening the file and navigating the user away from the page.
  window.addEventListener('dragover', preventDefault);
  window.addEventListener('drop', preventDefault);
});

onUnmounted(() => {
  // Add a listener to the window to prevent drag/drops that miss the dropzone
  // from opening the file and navigating the user away from the page.
  window.removeEventListener('dragover', preventDefault);
  window.removeEventListener('drop', preventDefault);
});

const fileTypeExtensions = computed(() => props.fileTypeLabels.join(', '));

const toTotalProgress = () => progresses.value.reduce((a, b) => a + b, 0);
const progressPercent = () =>
  (toTotalProgress() / progresses.value.length || 0).toFixed(2);

const handleUpload = async (event: Event) => {
  await props.beforeFileUpload();
  const target = event.target as HTMLInputElement;
  const files = target.files || [];
  track('order:cadfile-uploading', {
    filesCount: files.length,
    source: props.name,
    order: undefined,
  });
  isOver.value = false;
  isLoading.value = true;
  errors.value.splice(0);
  return Promise.all(
    Array.from(files).map((file, index) => {
      progresses.value.push(0);
      const progress = (percent: number) => {
        progresses.value[index] = percent;
        fillEl.value?.style.setProperty('--fillWidth', progressPercent());
      };
      const handleError = (message: string) => errors.value.push(message);
      return props.handleSingleFile(file, progress, handleError);
    })
  )
    .then(async (r: unknown) => {
      const filetypes = (r as { data: CadFile }[])
        .map((it) => it?.data)
        .filter(Boolean)
        .map((it: CadFile) => it.filetype)
        .join(', ');
      await props.afterFileUpload(filetypes, props.name);
    })
    .finally(async () => {
      isLoading.value = false;
      target.value = ''; // clear file input so uploading files with the same name works
      setTimeout(() => progresses.value.splice(0), 500);
    });
};

const preventDefault = (e: Event) => {
  const target = <HTMLInputElement>e.target;
  if (target.tagName !== 'INPUT' && target.getAttribute('type') !== 'file') {
    e = e || event;
    e.preventDefault();
  }
};
</script>
