export function isEven(n) {
  return 0 === n % 2;
}

// https://wiki.c2.com/?BankersRounding
// https://stackoverflow.com/a/32271909
export function bankersRound(x) {
  var r = Math.round(x);
  return (x > 0 ? x : -x) % 1 === 0.5 ? (isEven(r) ? r : r - 1) : r;
}
