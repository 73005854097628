<template>
  <div
    :class="`formulate-input-element formulate-input-element--amount`"
    :data-type="context.type"
  >
    <AmountInput
      v-model="value"
      v-bind="context.attributes"
      :class="context.classes.input"
      @blur="context.blurHandler"
    />
    <div
      class="formulate-input-element__currency-symbol"
      :data-currency-symbol="currencySymbol"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ToBeFixed } from '@cutr/constants/utility-types';
import { getCurrencySymbol } from '@/helpers/currencyHelpers';
import AmountInput from './AmountInput.vue';

type Props = {
  context: ToBeFixed;
};

const props = defineProps<Props>();

const currency = computed(() => props.context.attributes.currency);
const currencySymbol = computed(() =>
  currency.value ? getCurrencySymbol(currency.value) : ''
);

const value = computed({
  get() {
    return props.context.model || 0;
  },
  set(newValue) {
    // eslint-disable-next-line vue/no-mutating-props
    props.context.model = newValue;
  },
});
</script>
