<template>
  <div
    :class="`formulate-input-element formulate-input-element--amount`"
    :data-type="context.type"
  >
    <vue-numeric
      separator=","
      v-model="value"
      :minus="!!context.attributes.allowNegative"
      :precision="context.attributes.precision || 0"
      :currency="context.attributes.currency"
      :unit="context.attributes.unit"
      :placeholder="context.attributes.placeholder"
      :disabled="context.attributes.disabled"
      @blur="onBlur"
    ></vue-numeric>
  </div>
</template>

<script>
import VueNumeric from './VueNumeric.vue';

export default {
  name: 'FormulateNumeric',

  components: {
    VueNumeric,
  },
  methods: {
    onBlur: function (e) {
      this.props.context.attributes.onBlur?.(e);
    },
  },

  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  computed: {
    value: {
      get() {
        return this.context.model;
      },
      set(val) {
        // eslint-disable-next-line vue/no-mutating-props
        this.context.model = val;
      },
    },
  },
};
</script>
