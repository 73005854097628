<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <div>
    <p>Available everywhere in our app using <code>&lt;Button /&gt;</code></p>
    <p>
      props: <code>{{ allProps.join(', ') }}</code>
    </p>
    <h4>Default</h4>
    <div class="flexAlign">
      <Button>default</Button>
      <Button disabled>default [disabled]</Button>
      <Button :loading="true">default [loading]</Button>
      <Button :loading-progress="70">default [loading]</Button>
      <Button href="#">default [a]</Button>
      <Button icon="volunteer_activism">icon</Button>
      <Button icon="volunteer_activism" :icon-before="true">icon-before</Button>
    </div>

    <div v-for="variant in colors" :key="'xx-' + variant">
      <h4>{{ variant }}</h4>
      <p>
        <code>&lt;Button color="{{ variant }}" /&gt;</code>
      </p>
      <div class="flexAlign">
        <Button :color="variant">{{ variant }}</Button>
        <Button :color="variant" disabled>{{ variant }} [disabled]</Button>
        <Button :color="variant" :loading="true">
          {{ variant }} [loading]
        </Button>
        <Button :color="variant" :loading-progress="30">
          {{ variant }} [loading]
        </Button>
        <Button :color="variant" href="#">{{ variant }} [a]</Button>
        <Button :color="variant" icon="volunteer_activism">icon</Button>
        <Button :color="variant" icon="volunteer_activism" :icon-before="true">
          icon-before
        </Button>
      </div>
    </div>
    <h2>Router</h2>
    <p>
      Allows to use links to internal routes.
      <code>&lt;Button to="pageName" /&gt;</code>
    </p>

    <div class="flexAlign">
      <Button to="playground">router-link</Button>
      <Button to="playground" tooltip="It's a tooltip!">tooltip</Button>
      <Button to="playground" disabled>disabled</Button>
      <Button to="playground" class="signal">customclass</Button>
      <Button to="playground" icon="volunteer_activism">icon</Button>
      <Button to="playground" icon="volunteer_activism" />
      <Button to="playground" class="sml">small</Button>
    </div>
    <h2>Button widths</h2>
    <p>
      Buttons have different available widths:
      <code>{{ sizes.join(', ') }}</code>
    </p>
    <div :style="{ display: 'grid', gap: '0.5rem' }">
      <div v-for="variant in sizes" :key="'size-' + variant">
        <Button :width="variant">{{ variant }}</Button>
      </div>
    </div>
    <h2>sml</h2>
    <p>
      <code>&lt;Button size="sml" /&gt;</code>
    </p>
    <div class="flexAlign">
      <Button size="sml">[sml]</Button>
      <Button
        v-for="variant in colors"
        :key="'btnsml-' + variant"
        :color="variant"
        size="sml"
      >
        {{ variant }} [sml]
      </Button>
      <Button size="sml" icon="volunteer_activism">icon</Button>
      <Button size="sml" icon="volunteer_activism" />
    </div>
    <h2>icons</h2>
    <p>
      <code>&lt;Button icon="volunteer_activism" /&gt;</code>
    </p>
    <div class="miniStack">
      <div class="flexAlign">
        <div>
          <Button icon="volunteer_activism" />
        </div>
        <div v-for="variant in colors" :key="'icon-' + variant">
          <Button
            :color="variant"
            icon="volunteer_activism"
            :tooltip="variant"
          />
        </div>
        <div>
          <Button size="sml" icon="volunteer_activism" tooltip="sml" />
        </div>
      </div>
      <div class="flexAlign">
        <div v-for="variant in icons" :key="'icon2-' + variant">
          <Button :icon="variant" />
        </div>
        <div v-for="variant in icons" :key="'icon3-' + variant">
          <Button :icon="variant" class="sml" />
        </div>
      </div>
    </div>
    <h2>menu</h2>
    <p>
      <code>&lt;Button variant="menu" /&gt;</code>
    </p>

    <div class="flexAlign">
      <Button variant="menu">menu</Button>
      <Button variant="menu" disabled>default [disabled]</Button>
      <Button variant="menu" :loading="true">default [loading]</Button>
      <Button variant="menu" href="#">default [a]</Button>
      <Button variant="menu" icon="volunteer_activism">icon</Button>
      <Button variant="menu" icon="volunteer_activism" :icon-before="true">
        icon-before
      </Button>
    </div>
    <h2>loading</h2>
    <p>
      <code>&lt;Button :loading="true" /&gt;</code> and
      <code>&lt;Button :loadingProgress="30" /&gt;</code>
    </p>

    <div class="flexAlign">
      <Button :loading="true">indefinite loading</Button>
      <Button v-for="p in [10, 30, 50, 70, 100]" :loading-progress="p" :key="p">
        progress {{ p }}
      </Button>
      <Button :loading="true" size="sml">indefinite loading</Button>
    </div>

    <h2>buttons group</h2>

    <div class="buttons-group compact">
      <Button icon="volunteer_activism" />
      <Button icon="volunteer_activism" />
      <Button icon="volunteer_activism" class="active" />
      <Button icon="volunteer_activism" />
      <Button icon="volunteer_activism" />
    </div>

    <h2>button-pair</h2>
    <p>
      Used in some modals to push buttons on each side. Prefer using `flexAlign`
      instead
    </p>
    <div class="miniStack">
      <div class="button-pair">
        <Button icon="volunteer_activism">very long text</Button>
      </div>
      <div class="button-pair">
        <Button icon="volunteer_activism">short text</Button>
        <Button icon="volunteer_activism">very long text</Button>
      </div>
      <div class="button-pair">
        <Button icon="volunteer_activism">text</Button>
        <Button icon="volunteer_activism">short text</Button>
        <Button icon="volunteer_activism">very long text</Button>
      </div>
    </div>
    <h2>flexAlign</h2>
    <p>
      When used in side a flex container, they will take up all the available
      space.
    </p>
    <div class="miniStack">
      <div class="flexAlign">
        <Button icon="volunteer_activism">very long text</Button>
      </div>
      <div class="flexAlign">
        <Button icon="volunteer_activism">short text</Button>
        <Button icon="volunteer_activism">very long text</Button>
      </div>
      <div class="flexAlign">
        <Button icon="volunteer_activism">text</Button>
        <Button icon="volunteer_activism">short text</Button>
        <Button icon="volunteer_activism">very long text</Button>
      </div>
    </div>
    <h2>flexAlign and fluid-width</h2>
    <p>
      Use <code>&lt;Button class="fluid-width" /&gt;</code> to have them take
      only the needed space
    </p>
    <div class="flexAlign">
      <Button width="fluid-width" icon="volunteer_activism">text</Button>
      <Button width="fluid-width" icon="volunteer_activism">short text</Button>
      <Button width="fluid-width" icon="volunteer_activism">
        very long text
      </Button>
    </div>
    <h2>Buttons onClick</h2>
    <p>
      Use a function that returns a promise to have the button show a loader
      until it's resolved
      <code>&lt;Button onClick="promise" /&gt;</code>
    </p>
    <div class="flexAlign">
      <Button width="fluid-width" icon="volunteer_activism" :onClick="promise">
        text
      </Button>
    </div>

    <section class="miniStack">
      <h2>Showcase</h2>
      <div class="flexAlign">
        <BaseCard variant="dark">
          <h2>Buttons using class button-pair</h2>
          <p>
            {{ longText }}
          </p>
          <div class="button-pair">
            <Button icon="send" color="signal">Send</Button>
            <Button icon="clear" color="alert-outline">Cancel</Button>
          </div>
        </BaseCard>
        <BaseCard>
          <h2>Buttons using class flexAlign</h2>
          <p>
            {{ longText }}
          </p>
          <div class="flexAlign">
            <Button icon="not_interested" color="green">Accept</Button>
            <Button icon="not_interested" color="alert">Decline</Button>
          </div>
        </BaseCard>
      </div>
      <div class="miniStack">
        <BaseCard variant="dark">
          <h2>Buttons using class button-pair</h2>
          <p>
            {{ longText }}
          </p>
          <div class="button-pair">
            <Button icon="send" color="signal">Send</Button>
            <Button icon="clear" color="alert-outline">Cancel</Button>
          </div>
        </BaseCard>
        <BaseCard>
          <h2>Buttons using class flexAlign</h2>
          <p>
            {{ longText }}
          </p>
          <div class="flexAlign">
            <Button icon="not_interested" color="green">Accept</Button>
            <Button icon="not_interested" color="alert">Decline</Button>
          </div>
        </BaseCard>
        <BaseCard>
          <h2>Buttons aligned right</h2>
          <p>
            {{ longText }}
          </p>
          <div class="flexAlign">
            <Button
              icon="not_interested"
              color="green"
              width="fluid-width"
              class="right"
            >
              Accept
            </Button>
            <Button icon="not_interested" class="alert fluid-width">
              Decline
            </Button>
          </div>
        </BaseCard>
      </div>

      <BaseCard class="flexAlign">
        <h4 style="flex: 1">Quote 1</h4>
        <div class="flexAlign">
          <Button color="white" width="fluid-width" icon="check">Ready</Button>
          <Button color="white" width="fluid-width" icon="visibility"
            >View</Button
          >
        </div>
      </BaseCard>
    </section>
  </div>
</template>

<script setup>
import Button, { sizes, colors } from '@/components/common/Button.vue';
const icons = [
  'forest',
  'cabin',
  'bakery_dining',
  'subway',
  'edit_attributes',
  'route',
  'navigation',
  'celebration',
];

const allProps = Object.keys(Button.props);

const longText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.`;

const promise = () => new Promise((res) => setTimeout(res, 1500));
</script>
