<template>
  <component :is="activeSessionType" />
</template>

<script>
import { useActiveUserStore } from '@/modules/store';

const Customer = () =>
  import(/* webpackChunkName: "billing-customer" */ './Customer/index.vue');
const Admin = () =>
  import(/* webpackChunkName: "billing-admin" */ './Admin/index.vue');

export default {
  components: {
    Customer,
    Admin,
  },

  setup() {
    const store = useActiveUserStore();
    return {
      activeSessionType: store.activeSessionType,
    };
  },
};
</script>
