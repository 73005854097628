<template>
  <FormulateHelp
    v-if="
      $feature.isEnabled('help_above_input')
        ? context.slotProps.help.helpBelowInput
        : true
    "
    :context="context"
  />
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
};
</script>
