<template>
  <div class="board" ref="tableContainer">
    <div :class="$style.stack">
      <section>
        <h1>{{ $t('cutlist:freeTextIntake.emailHeader') }}</h1>
        <h3>{{ $t('cutlist:freeTextIntake.emailSubHeader') }}</h3>
        <h4 class="buffer-top">
          {{ $t('cutlist:freeTextIntake.requestTitle') }}
        </h4>

        <BaseCard variant="darker">
          <p v-for="(p, index) in emailHtml" :key="index">
            {{ p }}
          </p>
        </BaseCard>
      </section>

      <section>
        <h4>{{ $t('cutlist:freeTextIntake.freeFormCutlistHeader') }}</h4>
        <div class="flex-hor-between buffer-top">
          <TabNav :options="materialGroups" v-model="currentGroupId" />
        </div>
        <ag-grid-vue
          domLayout="autoHeight"
          style="width: 100%"
          class="ag-theme-alpine buffer-top"
          @grid-ready="onGridReady"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="parts"
          :getLocaleText="AgGridGetLocaleText"
        />
      </section>
      <section>
        <h4>{{ $t('cutlist:freeTextIntake.notes') }}</h4>
        <p v-for="(p, index) in notesHtml" :key="index">
          {{ p }}
        </p>
      </section>
      <section>
        <div class="flexAlign">
          <template v-if="freeFormCutlist?.orderId">
            <span>{{ $t('cutlist:freeTextIntake:orderCreated') }}</span>
            <Button
              :onClick="
                () =>
                  router.push({
                    name: `order:cutlist`,
                    params: { id: freeFormCutlist?.orderId || '' },
                  })
              "
              color="signal"
              class="units-3"
              icon="chevron_right"
            >
              {{ $t('cutlist:freeTextIntake.gotoOrder') }}
            </Button>
          </template>
          <template v-else>
            <Button
              :onClick="() => markFreeFormCutlistCorrectness(false)"
              color="white"
              class="units-3"
              icon="chevron_right"
            >
              {{ $t('cutlist:freeTextIntake.wrong') }}
            </Button>
            <Button
              :onClick="() => markFreeFormCutlistCorrectness(true)"
              color="signal"
              class="units-3"
              icon="chevron_right"
            >
              {{ $t('cutlist:freeTextIntake.correct') }}
            </Button>
          </template>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AgGridVue } from 'ag-grid-vue';

import { AgGridGetLocaleText } from '@/helpers/i18n';
import { computed, onMounted, ref } from 'vue';
import {
  useApi,
  useRoute,
  useRouter,
  useToast,
  useTranslation,
} from '@/composables/plugins';
import { FreeFormCutlist } from '@cutr/constants/cutlist';
import { GridReadyEvent } from 'ag-grid-community';
import { useDraftOrder } from '@/elements/Launchpad/composables';
import TabNav from '@/elements/TabNav.vue';
import { watch } from 'vue';

type FreeFormCutlistResponse = {
  textInput: string;
  orderId?: string;
  generatedData: FreeFormCutlist;
};
const freeFormCutlist = ref<FreeFormCutlistResponse | null>(null);

const materialGroups = computed(
  () =>
    freeFormCutlist.value?.generatedData?.groups?.map(
      (g: { id: string; name: string }) => ({
        value: g.id,
        label: g.name,
      })
    ) || []
);
watch(materialGroups, (newGroups) => {
  currentGroupId.value = newGroups?.[0]?.value || undefined;
});

const currentGroupId = ref<string | undefined>();

const api = useApi();
const toast = useToast();
const route = useRoute();
const router = useRouter();
const { order, variantId, beforeCreate } = useDraftOrder();

const emailHtml = computed(() => freeFormCutlist.value?.textInput?.split('\n'));

const notesHtml = computed(() =>
  freeFormCutlist.value?.generatedData?.notes?.split('\n')
);

const parts = computed(() =>
  !currentGroupId.value
    ? freeFormCutlist.value?.generatedData?.parts
    : freeFormCutlist.value?.generatedData?.parts.filter(
        (p) => p.groupId === currentGroupId.value
      )
);

const { t } = useTranslation();

const defaultColDef = {
  sortable: true,
  filter: true,
  resizable: true,
};

const columnDefs = [
  {
    headerName: t('cutlist:freeTextIntake.name'),
    field: 'label',
    width: 700,
  },
  {
    headerName: t('cutlist:freeTextIntake.quantity'),
    field: 'quantity',
  },
  {
    headerName: t('cutlist:freeTextIntake.lengthMM'),
    field: 'lengthMM',
  },
  {
    headerName: t('cutlist:freeTextIntake.widthMM'),
    field: 'widthMM',
  },
];

onMounted(() => {
  fetchFreeFormCutlist();
});

const onGridReady = (params: GridReadyEvent) => {
  params.api.sizeColumnsToFit();
};

const markFreeFormCutlistCorrectness = (isCorrect: boolean) =>
  api
    .post(`/v2/free-form-cutlist/${route.params.id}`, { isCorrect })
    .then(async ({ data }) => {
      await onCutlistCreated(data.data.id);
    })
    .catch((err) => {
      console.error(err);
      toast.error('Error creating order from extracted cutlist');
    });

const onCutlistCreated = async (cutlistId: string) => {
  // create a new order with this cutlistId
  await beforeCreate(true, cutlistId);

  // redirect to that order
  await router.push({
    name: `order:cutlist`,
    params: { id: order.value?.id || '' },
    query: {
      variantId: variantId.value,
    },
  });
};

const fetchFreeFormCutlist = () => {
  if (!route?.params?.id) {
    return router.replace({ name: 'notfound' });
  }

  api
    .get(`/v2/free-form-cutlist/${route.params.id}`)
    .then(({ data }) => {
      freeFormCutlist.value = data.data;
    })
    .catch((err) => {
      if (err.response?.status === 404) {
        return router.replace({ name: 'notfound' });
      }
      console.error(err);
      toast.error('Error fetching cutlist');
    });
};
</script>

<style module>
.stack {
  width: 900px;
  margin: auto;
  display: grid;
  gap: 1rem;
}

@media (max-width: 650px) {
  .data {
    grid-template-columns: auto;
    gap: 0;
  }
}
</style>
