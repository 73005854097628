<template>
  <div style="position: relative">
    <VDropdown :shown="open">
      <Button
        :class="['white', 'compact', hasReloaded && $style.buttonHighlight]"
        icon="chat_bubble_outline"
      />
      <span
        :class="[$style.counter, hasReloaded && $style.counterHasNew]"
        v-if="notificationsStore.unreadCount > 0"
        >{{ notificationsStore.unreadCount }}</span
      >
      <template #popper>
        <NotificationMessages :class="$style.wrapper" />
      </template>
    </VDropdown>
  </div>
</template>
<script setup lang="ts">
import { watch, ref, onMounted } from 'vue';
import { useNotificationsSetup } from '@/elements/Notifications/store';
import NotificationMessages from './NotificationMessages.vue';

const notificationsStore = useNotificationsSetup();

const open = ref(false);
const hasReloaded = ref(false);

watch(
  () => notificationsStore.unreadCount,
  (count) => {
    if (!count) {
      hasReloaded.value = false;
      return;
    }
    hasReloaded.value = true;
    setTimeout(() => {
      hasReloaded.value = false;
    }, 1000);
  }
);

onMounted(() => {
  if (window.location.search.includes('notifications=true')) {
    open.value = true;
  }
});
</script>
<style module>
.wrapper {
  display: grid;
  width: 26rem;
  max-height: 60vh;
  overflow-y: scroll;
}

.counter {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 100%;
  width: 1.7em;
  height: 1.7em;
  background-color: var(--color-error);
  color: var(--gray-1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border: 1px solid var(--gray-1);
  transform: translate(25%, -25%);
  transition: all 0.5s;
}

.counterHasNew {
  font-size: 14px;
}

.buttonHighlight {
  background-color: var(--gold-1) !important;
}
</style>
