<template>
  <Toggle
    :firstOption="firstOption"
    :secondOption="secondOption"
    :disabled="disabled"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
  ></Toggle>
</template>
<script setup lang="ts">
import { useTranslation } from '@/composables/plugins';
import Toggle from './Toggle.vue';

const { t } = useTranslation();

const firstOption = {
  label: t(`common:boolean.no`),
  value: false,
};
const secondOption = {
  label: t(`common:boolean.yes`),
  value: true,
};

type Props = {
  value?: boolean;
  disabled?: boolean;
};

defineProps<Props>();
</script>
