<template>
  <footer>
    <div>
      <span class="text-subtle">{{ $t('landing-page:trustedBy') }}</span>
      <div :class="$style.logos">
        <component width="100" :is="iconsMap.logoFinchBuildings" />
        <component width="72" :is="iconsMap.logoLiffin" />
        <component height="54" :is="iconsMap.logoEventrent" />
        <component width="64" :is="iconsMap.logoCampr" />
      </div>
    </div>

    <div>
      <div>
        <h3>{{ $t('landing-page:help.title') }}</h3>
      </div>
      <div :class="$style.contacts">
        <div>
          <strong>{{ $t('landing-page:help.call') }}</strong>
          <p class="text-subtle">
            <i18next :translation="$t('landing-page:help.callDescription')">
              <template #tel>
                <a href="tel:+310202619355">{{ '+31 (0)20 26 19 355' }}</a>
              </template>
            </i18next>
          </p>
        </div>
        <div>
          <strong>{{ $t('landing-page:help.email') }}</strong>
          <p class="text-subtle">
            <i18next :translation="$t('landing-page:help.emailDescription')">
              <template #email>
                <a href="mailto:hello@cutr.com">{{ 'hello@cutr.com' }}</a>
              </template>
            </i18next>
          </p>
        </div>
        <div>
          <strong>{{ $t('landing-page:help.chat') }}</strong>
          <p class="text-subtle">
            {{ $t('landing-page:help.chatDescription') }}
            <a @click.prevent="openChat" href="#">{{
              $t('landing-page:help.chatOpen')
            }}</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { iconsMap } from '@/components/common/custom-icons';
import { useRoute, useTrack } from '@/composables/plugins';
let hasIntercom = false;
let attempts = 0;

const checkIntercom = async () => {
  while (!hasIntercom) {
    await new Promise((resolve) => setTimeout(resolve, attempts * 1000));
    // @ts-expect-error Intercom might be on window
    hasIntercom = Boolean(window.Intercom);
    attempts++;

    if (attempts > 10) return;
  }
};

const track = useTrack();
const route = useRoute();

const openChat = () => {
  track('intercom:button:click', {
    page: route.name,
  });
  // @ts-expect-error Intercom might be on window
  hasIntercom && window.Intercom('showNewMessage');
};
checkIntercom();
</script>
<style module>
.logos {
  padding-top: 2rem;
  display: flex;
  gap: 1.5rem;

  & svg {
    max-height: 3rem;
  }
}

.contacts {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;

  & a {
    white-space: nowrap;
  }
}

@media screen and (max-width: 860px) {
  .contacts {
    gap: 1rem;
  }

  .logos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}
</style>
