import { getFormattingLocale } from './format-plugin';

export function daysOfWeek(weekday = 'long') {
  const format = new Intl.DateTimeFormat(getFormattingLocale(), { weekday })
    .format;
  return [...Array(7).keys()].map((day) =>
    format(new Date(Date.UTC(2021, 5, day)))
  );
}

export function toDaysPretty(daysArray) {
  if (!daysArray) return null;
  const days = daysOfWeek('short');
  return daysArray.map((it) => days[it]).join(', ');
}

export function compareDates(a, b) {
  return Date.parse(a) - Date.parse(b);
}
