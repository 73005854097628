import { track } from './event-handlers';
import { initializeConsentManager } from './consent-manager';

const Tracker = {
  install(Vue, { router }) {
    const consentManager = initializeConsentManager();
    track.openConsentManager = consentManager.openConsentManager;

    Vue.$track = track;
    Vue.prototype.$track = track;

    router.afterEach((to, from) => {
      const hasNavigated = Boolean(from.name);
      if (hasNavigated) {
        consentManager.implyConsent();
      }

      if (!to.meta.deferPageTracking) {
        track('page', { title: to.meta.title });
      }
    });
  },
};

export default Tracker;
export { track };
