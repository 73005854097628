<template>
  <div>
    <div v-if="loading">{{ $t('common:loading') }}</div>
    <div v-else-if="error">
      <p>{{ error }}</p>
      <Button @click="fetchOrganisations">{{
        $t('common:actions.retry')
      }}</Button>
    </div>
    <ul v-else :class="$style.organisations">
      <li v-for="org in organisations" :key="org.id">
        <BaseCard
          :border="true"
          :class="[
            $style.organisation,
            org.type === store.activeSessionType && $style.selected,
            org.type === store.activeSessionType &&
              disableCurrent &&
              $style.disabled,
          ]"
          :on-click="
            org.type === store.activeSessionType && disableCurrent
              ? undefined
              : () => selectOrganisation(org)
          "
        >
          <div :class="$style.orgContent">
            <div :class="$style.badgeColumn">
              <Badge :class="org.type" variant="pill">{{
                $t(`auth:orgTypes.${org.type.toLowerCase()}`)
              }}</Badge>
            </div>
            <div :class="$style.infoColumn">
              <div :class="$style.orgName">{{ org.name }}</div>
              <div :class="$style.orgDescription">
                {{ $t(`auth:orgDescriptions.${org.type.toLowerCase()}`) }}
              </div>
            </div>
          </div>
        </BaseCard>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useApi, useTranslation } from '@/composables/plugins';
import { useActiveUserStore } from '@/modules/store';
import Badge from '@/components/elements/Badge.vue'; // Make sure to import the Badge component

const props = defineProps<{
  afterOrganisationSelected: () => Promise<void>;
  disableCurrent?: boolean;
}>();

const organisations = ref<
  { id: string; name: string; type: 'Maker' | 'Customer' }[]
>([]);

const loading = ref(false);
const error = ref<string | null>(null);
const api = useApi();
const { t } = useTranslation();

const store = useActiveUserStore();

const selectOrganisation = async ({ type }: { type: 'Maker' | 'Customer' }) => {
  const { data } = await api.post('/v2/sessions/type', { type });
  store.setActiveUser(data.data);
  await props.afterOrganisationSelected();
};

const fetchOrganisations = async () => {
  loading.value = true;
  error.value = null;
  try {
    const { data } = await api.get('/v2/sessions/organisations');
    organisations.value = data.data;
  } catch (err) {
    error.value = t('common:errors.genericRequestFail');
  } finally {
    loading.value = false;
  }
};

onMounted(fetchOrganisations);
</script>
<style module>
.organisations {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none; /* Remove bullet points */
  padding-left: 0; /* Remove default padding */
}

.organisations li {
  margin-left: 0; /* Remove any left margin */
}

.orgContent {
  display: grid;
  grid-template-columns: 7.5rem auto;
  align-items: center;
}

.badgeColumn {
  flex: 0 0 auto;
  margin-right: 1rem;
}

.infoColumn {
  flex: 1;
}

.orgName {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.orgDescription {
  font-size: 0.9em;
  color: var(--gray-8);
}

.selected {
  border-color: var(--gray-8);

  .orgName {
    font-weight: bold;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
