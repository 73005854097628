<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <section class="miniStack">
    <p>Collection of todos to be displayed all over the platform.</p>
    <div class="miniStack">
      <div>
        <h3>todos</h3>
        <BaseCard>
          <Todo
            v-for="todo in derivedTodos"
            :key="todo.isDone + todo.key"
            :todo="todo"
            orderId="no-order"
          />
          <Todo
            v-for="todo in persistedTodos"
            :key="todo.isDone + todo.key"
            :todo="todo"
            orderId="no-order"
            :onChecked="() => {}"
          />
        </BaseCard>
        <div />
      </div>
    </div>
  </section>
</template>
<script setup>
import Todo from '../elements/Order/Project/Todo.vue';

const derivedTodos = [
  {
    key: 'create-quote',
    roles: ['Admin'],
    hideIfDone: false,
    isActive: true,
    isDone: false,
  },
  {
    key: 'nest-material',
    roles: ['Admin'],
    hideIfDone: false,
    isActive: true,
    isDone: false,
  },
  {
    key: 'accept-offer',
    roles: ['Admin'],
    amount: 16786,
    currency: 'eur',
    hideIfDone: false,
    isActive: true,
    isDone: false,
  },
];

const persistedTodos = [
  {
    key: 'scrub-names',
    roles: ['Admin'],
    hideIfDone: false,
    isActive: true,
    isDone: false,
  },
  {
    key: 'scrub-names',
    roles: ['Admin'],
    hideIfDone: false,
    isActive: true,
    isDone: true,
  },
];
</script>
