<template>
  <component :is="triggerOnHover ? 'VMenu' : 'VDropdown'">
    <Button
      :class="triggerClass"
      :icon="triggerIcon"
      :iconSecondary="triggerIconSecondary"
      :tooltip="triggerTooltip"
      :color="triggerColor"
      :size="triggerSize"
      name="dropdownTrigger"
      :disabled="isDisabled"
      :data-cy="triggerDataCy"
    >
      {{ triggerLabel }}
    </Button>
    <template #popper="{ hide }">
      <menu class="btn-menu">
        <li v-for="button in buttons" :key="button.key || button.label">
          <hr v-if="button.type === 'separator'" class="buffer-sm" />
          <component
            v-if="button.type !== 'separator'"
            :is="button.type"
            v-bind="omit(button, 'on')"
            v-on="button.on(hide)"
            >{{ button.label }}</component
          >
        </li>
      </menu>
    </template>
  </component>
</template>

<script setup lang="ts">
import omit from 'lodash/omit';
import { computed } from 'vue';
import type { DropdownMenuButton } from './types';
import { colors } from './Button.vue';

type Props = {
  triggerLabel?: string;
  triggerIcon?: string;
  triggerIconSecondary?: string;
  triggerClass?: string | string[] | Record<string, unknown>;
  triggerTooltip?: string;
  triggerColor?: (typeof colors)[number];
  triggerSize?: 'sml';
  isDisabled?: boolean;
  buttonsList: DropdownMenuButton[];
  triggerOnHover?: boolean;
  triggerDataCy?: string;
};

const props = withDefaults(defineProps<Props>(), {
  triggerClass: 'white',
  triggerIcon: 'expand_more',
});

const buttons = computed(() =>
  props.buttonsList.filter(Boolean).map((button) => ({
    type: 'Button',
    variant: 'menu',
    iconBefore: true,
    on: () => ({}),
    ...button,
  }))
);
</script>
