<template>
  <div
    :class="`formulate-input-element formulate-input-element--toggle`"
    :data-type="context.type"
  >
    <ToggleBoolean
      v-model="value"
      v-bind="context.attributes"
      :class="context.classes.input"
      :disabled="context.attributes?.disabled"
      @blur="context.blurHandler"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ToBeFixed } from '@cutr/constants/utility-types';
import ToggleBoolean from '../common/ToggleBoolean.vue';

type Props = {
  context: ToBeFixed;
};

const props = defineProps<Props>();

const value = computed({
  get() {
    if (props.context.model === '') return props.context.value;
    return props.context.model;
  },
  set(newValue) {
    // eslint-disable-next-line vue/no-mutating-props
    props.context.model = newValue;
  },
});
</script>
