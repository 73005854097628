<template>
  <fragment>
    <component
      :is="tag"
      v-for="(line, index) in lines"
      :key="index"
      :class="lineClass"
      >{{ line }}</component
    >
  </fragment>
</template>

<script>
// Renders a block of text with newlines into paragraphs

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
    lineClass: {
      type: String,
    },
  },

  computed: {
    lines() {
      return this.text
        .trim()
        .split('\n')
        .map((line) => line.trim())
        .filter(Boolean);
    },
  },
};
</script>
