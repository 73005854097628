import EmailIntakeCutlist from '@/components/pages/EmailIntakeCutlist.vue';
import {
  PlatformConfig,
  AdminBillingCustomer,
  AdminBillingMaker,
  AdminBillingOther,
  Billing,
  CutlistOrders,
  CutlistOrder,
  Files,
  Home,
  Materials,
  NotFound,
  Order,
  Organisation,
  Organisations,
  MakerOrganisations,
  MakerOrganisation,
  MakerOrganisationForm,
  PasswordRequest,
  PasswordReset,
  RFQView,
  RfqList,
  Playground,
  Settings,
  Signin,
  Signup,
  Tags,
  Users,
  Workspace,
  Debug,
  CustomerOrganisationsAdmin,
  CustomerOrganisationAdmin,
  CustomerOrganisationForm,
  DxfConversionTool,
  AcceptWithAuthCode,
  CutlistUtils,
  PromoCodesAdmin,
  Checkout,
  AccountContactDetails,
  AccountNotifications,
  AccountOrganisationDetails,
  AccountAddresses,
  AccountMembers,
  AccountWrapper,
  TestData,
  UserWrapper,
  AdminSettings,
  ContactDetails,
} from '../components/pages';
import { components } from '../components/playground/playground';
import { Roles } from './roles';
import { allTabs } from '@/elements/Order/navigation';
import SelectOrganisation from '@/components/pages/SelectOrganisation.vue';

const nav = [
  {
    name: 'home',
    title: 'NewQuote',
    show: ({ userStore }) =>
      !['Admin', 'Maker'].includes(userStore.activeSessionType) &&
      !userStore.userCutlistAdminSourceId,
  },
  {
    name: 'workspace',
    title: 'Orders',
    show: ({ userStore }) => !userStore.userCutlistAdminSourceId,
  },
  {
    name: 'cutlistOrders',
    title: 'CutlistOrders',
    show: ({ userStore }) =>
      userStore.isAdmin || !!userStore.userCutlistAdminSourceId,
  },
  {
    name: 'rfqs',
    title: 'RFQs',
    show: ({ userStore }) => !userStore.userCutlistAdminSourceId,
  },
  {
    name: 'users',
    title: 'Users',
    type: 'platform',
  },
  {
    name: 'organisations',
    title: 'Organisations',
    type: 'platform',
    show: ({ feature }) => feature.isEnabled('CTR-9599-user-org-management'),
  },
  {
    name: 'makerOrganisations',
    title: 'Organisations',
    type: 'platform',
    show: ({ feature }) => !feature.isEnabled('CTR-9599-user-org-management'),
  },
  {
    name: 'customerOrganisations',
    title: 'Customer Organisations',
    type: 'platform',
    show: ({ feature }) => !feature.isEnabled('CTR-9599-user-org-management'),
  },
  {
    name: 'materials',
    title: 'Materials',
    type: 'platform',
  },
  {
    name: 'promoCodes',
    title: 'Promo Codes',
    type: 'platform',
  },
  {
    name: 'tags',
    title: 'Tags',
    type: 'platform',
  },
  {
    name: 'billing',
    title: 'Billing',
    show: ({ userStore }) => !userStore.userCutlistAdminSourceId,
  },
];

//  These routes (name) will force a logout on load.
const routeKillSession = {
  passwordrequest: true,
  passwordreset: true,
  demologin: true,
  signup: true,
};

const routes = [
  { path: '/', redirect: { name: 'home' } },
  {
    path: '/organisations/:id',
    redirect: (to) => ({ path: `/maker-organisations/${to.params.id}` }),
  },
  {
    path: '/organisations/:id/view',
    component: Organisation,
    meta: { title: 'Organisation', allowedRoles: Roles.ADMIN_ONLY },
    children: [
      {
        path: '',
        name: 'organisations:details',
        component: Organisation,
        meta: { title: 'Organisation Details' },
      },
      {
        path: 'notifications',
        name: 'organisations:notifications',
        component: Organisation,
        meta: { title: 'Notifications' },
      },
      {
        path: 'addresses',
        name: 'organisations:addresses',
        component: Organisation,
        meta: { title: 'Addresses' },
      },
      {
        path: 'members',
        name: 'organisations:members',
        component: Organisation,
        meta: { title: 'Members' },
      },
    ],
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: 'Home',
      allowedRoles: Roles.ALL,
    },
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin,
    meta: {
      title: 'Signin',
      allowedRoles: Roles.NOT_LOGGED_IN,
    },
  },
  {
    path: '/signin/select-organisation',
    name: 'signin:selectOrganisation',
    component: SelectOrganisation,
    meta: {
      title: 'Signin',
      allowedRoles: new Set([Roles.customer, Roles.maker, Roles.user]),
    },
  },
  {
    path: '/signup/:code?',
    name: 'signup',
    component: Signup,
    meta: {
      title: 'Sign up',
      allowedRoles: Roles.NOT_LOGGED_IN,
    },
  },
  {
    path: '/passrequest',
    name: 'passwordrequest',
    component: PasswordRequest,
    meta: {
      title: 'Request a password reset',
      allowedRoles: Roles.NOT_LOGGED_IN,
    },
  },
  {
    path: '/passreset/:code',
    name: 'passwordreset',
    component: PasswordReset,
    meta: {
      title: 'Reset your password',
      allowedRoles: Roles.NOT_LOGGED_IN,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'Users',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },

  {
    path: '/users/:userId/view',
    component: UserWrapper,
    meta: {
      allowedRoles: Roles.ADMIN_ONLY,
    },
    children: [
      {
        path: '',
        name: 'users:details',
        component: AdminSettings,
        meta: { title: 'User Details' },
      },
      {
        path: 'contact',
        name: 'users:contact',
        component: ContactDetails,
        meta: { title: 'Contact Details' },
      },
    ],
  },

  {
    path: '/users/:userId',
    component: Settings,
    meta: {
      allowedRoles: Roles.ADMIN_ONLY,
    },
    children: [
      {
        path: '',
        name: 'users:settings:admin',
        component: Settings,
        meta: { title: 'Admin Settings' },
      },
      {
        path: 'settings',
        name: 'users:settings',
        component: Settings,
        meta: { title: 'Account Details' },
      },
      {
        path: 'addresses',
        name: 'users:settings:addresses',
        component: Settings,
        meta: { title: 'Address Book' },
      },
      {
        path: 'notifications',
        name: 'users:settings:notifications',
        component: Settings,
        meta: { title: 'Notifications' },
      },
      {
        path: 'locale',
        name: 'users:settings:locale',
        component: Settings,
        meta: { title: 'Locale' },
      },
    ],
  },

  {
    path: '/neworder',
    redirect: '/home',
  },
  {
    path: '/order/:id',
    name: 'order',
    component: Order,
    meta: {
      deferPageTracking: true,
      disableAppChrome: true,
      allowedRoles: new Set([
        Roles.admin,
        Roles.maker,
        Roles.customer,
        Roles.unauthenticated,
      ]),
    },
    children: [
      ...allTabs.map((path) => ({
        path,
        name: `order:${path}`,
        component: Order,
        meta: { deferPageTracking: true, disableAppChrome: true },
      })),
      {
        path: 'production',
        redirect: 'files',
      },
    ],
  },
  {
    path: '/checkout/:orderId',
    name: 'checkout',
    component: Checkout,
    meta: {
      disableAppChrome: true,
      allowedRoles: new Set([Roles.customer, Roles.admin]),
      noBackground: true,
    },
  },
  {
    path: '/settings',
    component: AccountWrapper,
    meta: {
      allowedRoles: Roles.LOGGED_IN,
    },
    children: [
      {
        path: '',
        name: 'account',
        component: AccountContactDetails,
        meta: { title: 'Contact Details' },
      },
      {
        path: 'organisation',
        name: 'account:organisation',
        component: AccountOrganisationDetails,
        meta: { title: 'Organisation Details' },
      },
      {
        path: 'notifications',
        name: 'account:notifications',
        component: AccountNotifications,
        meta: { title: 'Notifications' },
      },
      {
        path: 'addresses',
        name: 'account:addresses',
        component: AccountAddresses,
        meta: { title: 'Address Book' },
      },
      {
        path: 'members',
        name: 'account:members',
        component: AccountMembers,
        meta: { title: 'Members' },
      },
    ],
  },
  {
    path: '/account',
    meta: {
      allowedRoles: Roles.LOGGED_IN,
    },
    component: Settings,
    children: [
      {
        path: '',
        name: 'settings',
        component: Settings,
        meta: { title: 'Account Details' },
      },
      {
        path: 'addresses',
        name: 'settings:addresses',
        component: Settings,
        meta: { title: 'Address Book' },
      },
      {
        path: 'notifications',
        name: 'settings:notifications',
        component: Settings,
        meta: { title: 'Notifications' },
      },
      {
        path: 'locale',
        name: 'settings:locale',
        component: Settings,
        meta: { title: 'Locale' },
      },
      {
        path: 'test-data',
        name: 'settings:testData',
        component: Settings,
        meta: { title: 'Test Data' },
      },
    ],
  },
  {
    path: 'test-data',
    name: 'testData',
    component: TestData,
    meta: { title: 'Test Data', allowedRoles: Roles.ADMIN_ONLY },
  },
  {
    path: '/maker-organisations',
    name: 'makerOrganisations',
    component: MakerOrganisations,
    meta: {
      title: 'Partner Organisations',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },
  {
    path: '/maker-organisations/:id',
    name: 'makerOrganisation',
    component: MakerOrganisation,
    meta: {
      title: 'View Partner Organisation',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },
  {
    path: '/maker-organisations/:id/edit',
    name: 'makerOrganisationEdit',
    component: MakerOrganisationForm,
    meta: {
      title: 'Edit Partner Organisation',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },
  {
    path: '/customer-organisations',
    name: 'customerOrganisations',
    component: CustomerOrganisationsAdmin,
    meta: {
      title: 'Customer Organisations',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },
  {
    path: '/customer-organisations/:id',
    name: 'customerOrganisation',
    component: CustomerOrganisationAdmin,
    meta: {
      title: 'Customer Organisation',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },
  {
    path: '/customer-organisations/:id/edit',
    name: 'customerOrganisationEdit',
    component: CustomerOrganisationForm,
    meta: {
      title: 'Edit Customer Organisation',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },
  {
    path: '/debug/:id',
    name: 'debug',
    component: Debug,
    meta: {
      allowedRoles: new Set([Roles.admin]),
    },
  },
  {
    path: '/dev/cutlist-utils',
    name: 'dev:cutlist-utils',
    component: CutlistUtils,
    meta: {
      title: 'Cutlist utils',
      allowedRoles: new Set([Roles.admin]),
    },
  },
  {
    path: '/admin/config',
    name: 'admin:config',
    component: PlatformConfig,
    meta: {
      title: 'Platform Config',
      allowedRoles: new Set([Roles.admin]),
    },
  },
  {
    path: '/materials',
    name: 'materials',
    component: Materials,
    meta: {
      title: 'Materials',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },
  {
    path: '/promo-codes',
    name: 'promoCodes',
    component: PromoCodesAdmin,
    meta: {
      title: 'Promo Codes',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },
  {
    path: '/tags',
    name: 'tags',
    component: Tags,
    meta: {
      title: 'Tags',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },
  {
    path: '/billing',
    name: 'billing',
    component: Billing,
    meta: { allowedRoles: new Set([Roles.admin, Roles.customer]) },
    children: [
      {
        path: 'customer',
        name: 'billing:customer',
        component: AdminBillingCustomer,
        meta: {
          allowedRoles: Roles.ADMIN_ONLY,
          title: 'Customer Invoices',
          subNav: true,
        },
      },
      {
        path: 'maker',
        name: 'billing:maker',
        component: AdminBillingMaker,
        meta: {
          allowedRoles: Roles.ADMIN_ONLY,
          title: 'Maker Invoices',
          subNav: true,
        },
      },
      {
        path: 'other',
        name: 'billing:other',
        component: AdminBillingOther,
        meta: {
          allowedRoles: Roles.ADMIN_ONLY,
          title: 'Third Party Invoices',
          subNav: true,
        },
      },
    ],
  },
  {
    path: '/workspace',
    name: 'workspace',
    component: Workspace,
    meta: {
      title: 'Orders',
      allowedRoles: new Set([Roles.admin, Roles.maker, Roles.customer]),
    },
  },
  {
    path: '/cutlists',
    name: 'cutlistOrders',
    component: CutlistOrders,
    meta: {
      title: 'Cutlist Orders',
      allowedRoles: new Set([Roles.admin, Roles.maker, Roles.customer]),
    },
  },
  {
    path: '/cutlist/:id',
    name: 'cutlist',
    component: CutlistOrder,
    meta: {
      disableAppChrome: true,
      allowedRoles: new Set([Roles.admin, Roles.maker, Roles.customer]),
    },
  },
  {
    path: '/files',
    name: 'files',
    component: Files,
    meta: {
      title: 'Files',
      allowedRoles: new Set([Roles.admin, Roles.customer]),
    },
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: NotFound,
    meta: {
      title: 'Page not found',
      allowedRoles: Roles.ALL,
    },
  },
  {
    path: '/rfq/:id',
    name: 'rfq',
    component: RFQView,
    meta: {
      title: 'RFQ',
      allowedRoles: new Set([Roles.admin, Roles.maker, Roles.customer]),
      noBackground: true,
    },
  },
  {
    path: '/rfqs',
    name: 'rfqs',
    component: RfqList,
    meta: {
      title: 'RFQs',
      allowedRoles: new Set([Roles.admin, Roles.maker]),
    },
  },
  {
    path: '/playground',
    name: 'playground',
    component: Playground,
    meta: {
      title: 'Playground',
      allowedRoles: Roles.ADMIN_ONLY,
      noBackground: true,
    },
    children: components,
  },
  {
    path: '/dxf-conversion-tool',
    name: 'dxfConversionTool',
    component: DxfConversionTool,
    meta: {
      title: 'dxfConversionTool',
      whiteLabel: true,
      allowedRoles: Roles.ALL,
    },
  },
  {
    path: '/free-form-cutlist/:id',
    name: 'emailIntakeCutlist',
    component: EmailIntakeCutlist,
    meta: {
      title: 'Cutlist email',
      whiteLabel: true,
      allowedRoles: Roles.ALL,
    },
  },
  {
    path: '/:pricingType/:id/accept/:code',
    name: 'acceptPricing',
    component: AcceptWithAuthCode,
    meta: {
      allowedRoles: Roles.ALL,
    },
  },
  {
    path: '/organisations',
    name: 'organisations',
    component: Organisations,
    meta: {
      title: 'Organisations',
      allowedRoles: Roles.ADMIN_ONLY,
    },
  },
];

const routeIsAllowed = function (userType, target) {
  const route = [...target.matched]
    .reverse()
    .find((route) => route.meta?.allowedRoles);

  const allowedRoles = route?.meta?.allowedRoles;
  if (allowedRoles) {
    return allowedRoles.has(userType);
  }
  return false;
};

const handleRoute = function (next, type, target) {
  if (routeIsAllowed(type, target)) return next();
  if (type === 'Public') {
    return next({
      name: 'signin',
      query: { redirect: target.query.redirect || target.fullPath },
    });
  }
  if (type === 'User') {
    return next({
      name: 'signin:selectOrganisation',
      query: { redirect: target.query.redirect || target.fullPath },
    });
  }
  return next({ name: 'home' });
};

const pathIsAllowed = function (userType, pathName) {
  const route = routes.find((r) => r.name === pathName);
  if (!route) {
    return false;
  }
  const allowedRoles = route?.meta?.allowedRoles;
  if (allowedRoles) {
    return allowedRoles.has(userType);
  }
  return false;
};

export { routes, nav, routeKillSession, pathIsAllowed, handleRoute };
