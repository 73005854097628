const admin = 'Admin';
const customer = 'Customer';
const maker = 'Maker';
const publicRole = 'Public';
const unauthenticated = 'Unauthenticated';
const user = 'User';

export const Roles = {
  ALL: new Set([admin, maker, customer, publicRole, unauthenticated]),
  LOGGED_IN: new Set([admin, maker, customer]),
  NOT_LOGGED_IN: new Set([publicRole, unauthenticated, user]),
  ADMIN_ONLY: new Set([admin]),
  admin,
  maker,
  user,
  customer,
  public: publicRole,
  unauthenticated,
};
