import i18next from './i18n';
import _Vue, { PluginObject } from 'vue';

export const helpLink = (code: string) =>
  `http://help.cutr.ai/${i18next.resolvedLanguage}/articles/${code}`;

const HelpLinksPlugin: PluginObject<void> = {
  install(Vue: typeof _Vue) {
    Vue.prototype.$helpLink = helpLink;
  },
};

export default HelpLinksPlugin;
