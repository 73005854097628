import Vue from 'vue';

import PaneHeader from '../components/common/PaneHeader.vue';
import BaseCard from '../components/common/BaseCard.vue';
import Badge from '../components/elements/Badge.vue';
import Button from '../components/common/Button.vue';
import Toggle from '../components/common/Toggle.vue';
import ToggleBoolean from '../components/common/ToggleBoolean.vue';
import Icon from '../components/common/Icon.vue';
import Callout from '../components/elements/Callout.vue';
import FormulateAmount from '../components/elements/FormulateAmount.vue';
import FormulatePercentage from '../components/elements/FormulatePercentage.vue';
import FormulateNumeric from '../components/elements/FormulateNumeric.vue';
import FormulateDatePicker from '../components/elements/FormulateDatePicker.vue';
import FormulateDaysOfWeekPicker from '../components/elements/FormulateDaysOfWeekPicker.vue';
import FormulateFindAndSelectInput from '../components/elements/FormulateFindAndSelectInput.vue';
import FormulateInlineSelect from '../components/elements/FormulateInlineSelect.vue';
import CutrFormulateLabel from '../components/elements/FormulateLabel.vue';
import CutrFormulateHelp from '../components/elements/FormulateHelp.vue';
import FormulateVueSelect from '../components/elements/FormulateVueSelect.vue';
import HelpLink from '../components/elements/HelpLink.vue';
import LoadingDots from '../components/elements/LoadingDots.vue';
import Modal from '../components/common/Modal.vue';
import MultiLineText from '../components/elements/MultiLineText.vue';
import FormulateToggle from '@/elements/FormulateToggle.vue';
import FormulateQuantity from '@/elements/FormulateQuantity.vue';
import GenericTable from '../components/common/GenericTable.vue';

interface CutrComponents {
  Modal: typeof Modal;
  Button: typeof Button;
  Toggle: typeof Toggle;
  ToggleBoolean: typeof ToggleBoolean;
  Icon: typeof Icon;
  Callout: typeof Callout;
  HelpLink: typeof HelpLink;
  LoadingDots: typeof LoadingDots;
  PaneHeader: typeof PaneHeader;
  Badge: typeof Badge;
  MultiLineText: typeof MultiLineText;
  FormulateNumeric: typeof FormulateNumeric;
  FormulateAmount: typeof FormulateAmount;
  FormulatePercentage: typeof FormulatePercentage;
  FormulateToggle: typeof FormulateToggle;
  FormulateQuantity: typeof FormulateQuantity;
  FormulateDatePicker: typeof FormulateDatePicker;
  FormulateDaysOfWeekPicker: typeof FormulateDaysOfWeekPicker;
  FormulateFindAndSelectInput: typeof FormulateFindAndSelectInput;
  FormulateInlineSelect: typeof FormulateInlineSelect;
  CutrFormulateLabel: typeof CutrFormulateLabel;
  CutrFormulateHelp: typeof CutrFormulateHelp;
  FormulateVueSelect: typeof FormulateVueSelect;
  BaseCard: typeof BaseCard;
  GenericTable: typeof GenericTable;
}

const globalComponents: CutrComponents = {
  Modal,
  Button,
  Toggle,
  ToggleBoolean,
  Icon,
  Callout,
  HelpLink,
  LoadingDots,
  PaneHeader,
  Badge,
  MultiLineText,
  FormulateNumeric,
  FormulateAmount,
  FormulatePercentage,
  FormulateToggle,
  FormulateQuantity,
  FormulateDatePicker,
  FormulateDaysOfWeekPicker,
  FormulateFindAndSelectInput,
  FormulateInlineSelect,
  CutrFormulateLabel,
  CutrFormulateHelp,
  FormulateVueSelect,
  BaseCard,
  GenericTable,
};

export function registerGlobalComponents() {
  Object.entries(globalComponents).forEach(([name, component]) =>
    Vue.component(name, component)
  );
}

declare module 'vue' {
  export interface GlobalComponents extends CutrComponents {}
}
