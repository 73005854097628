import { supportedCurrencies, Currency } from '@cutr/constants/currencies';
import i18n from './i18n';
export const getCurrencySymbol = (currency: Currency): string =>
  (0)
    .toLocaleString(i18n.resolvedLanguage || 'en', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

export const getSupportedCurrenciesOptions = () =>
  supportedCurrencies.reduce<Partial<Record<Currency, string>>>(
    (acc, currency) => {
      acc[currency] = getCurrencySymbol(currency);
      return acc;
    },
    {}
  );
